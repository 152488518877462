<template>
  <NavBar />
  <router-view></router-view>
  <FooterWeb />
</template>

<script>
import NavBar from "../src/layouts/NavBar.vue";
import FooterWeb from "../src/layouts/FooterWeb.vue";
import UAParser from "ua-parser-js";
import MobileDetect from "mobile-detect";

export default {
  name: "App",
  components: {
    NavBar,
    FooterWeb,
  },
  data() {
    return {
      stylesFontsVariables: [],
      isMobile: false,
      deviceType: "",
      os: "",
      referer: "",
      refererSource: "",
    };
  },

  created() {
    const business = process.env.BUSINESS;
    const id = process.env.BRANCH;
    this.$store.commit("business/setBusinessName", business);
    this.$store.commit("business/setIdBranch", id);
  },
  mounted() {
    this.fetchStyles();
    window.addEventListener("resize", this.handleResize);
    this.detectDevice();
    this.detectReferer();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleResize() {
      // Actualiza isMobile basado en el ancho de la pantalla
      this.isMobile = window.innerWidth <= 800; // Puedes ajustar el umbral según tus necesidades
      this.applyStyles(this.isMobile ? "Mobile" : "Desktop");
    },
    applyStyles(device = "Desktop") {
      const stylesLocal = this.stylesFontsVariables.find((font) => font.device_type === device);
      const styles = {
        // aqui pondria al body, ya sea p,span,small,etc
        p: {
          fontFamily: stylesLocal.body_font_name,
          fontSize: `${stylesLocal.body_font_size}rem`,
          fontWeight: stylesLocal?.body_font_weight,
          color: stylesLocal.body_color?.accent,
          colorSecondary: stylesLocal.body_color?.secondary,
        },
        small: {
          fontFamily: stylesLocal.body_font_name,
          fontSize: `${stylesLocal.body_font_size}rem`,
          fontWeight: stylesLocal?.body_font_weight,
          color: stylesLocal.body_color?.accent,
          colorSecondary: stylesLocal.body_color?.secondary,
        },
        span: {
          fontFamily: stylesLocal.body_font_name,
          fontSize: `${stylesLocal.body_font_size}rem`,
          fontWeight: stylesLocal?.body_font_weight,
          color: stylesLocal.body_color?.accent,
          colorSecondary: stylesLocal.body_color?.secondary,
        },
      };
      // Itera sobre los diferentes tamaños de encabezados
      ["h1", "h2", "h3", "h4", "h5", "h6"].forEach((h) => {
        styles[h] = {
          fontFamily: stylesLocal[`${h}_font_name`],
          fontSize: `${stylesLocal[`${h}_font_size`]}rem`,
          fontWeight: stylesLocal[`${h}_font_weight`],
          color: stylesLocal[`${h}_color`]?.accent,
          colorSecondary: stylesLocal[`${h}_color`]?.secondary,
        };
      });

      this.$store.commit("business/setStyles", styles);
    },

    fetchStyles() {
      this.$store.dispatch("business/fetchStyles").then((resp) => {
        this.stylesFontsVariables = resp;
        this.applyStyles(window.innerWidth <= 800 ? "Mobile" : "Desktop");
      });
    },
    detectDevice() {
      const parser = new UAParser();
      const result = parser.getResult();
      this.os = result.os.name;
      const md = new MobileDetect(window.navigator.userAgent);
      this.deviceType = this.getDeviceType(md);
    },
    getDeviceType(md) {
      if (md.tablet()) {
        return "Tablet";
      } else if (md.mobile()) {
        return "Mobile";
      } else {
        return "Desktop";
      }
    },
    detectReferer() {
      this.referer = document.referrer;
      this.refererSource = this.getRefererSource(this.referer, window.location.href);
    },
    getRefererSource(referer, currentUrl) {
      if (referer === "" || referer === currentUrl) {
        return "Direct";
      } else if (referer.includes("google.com")) {
        return referer.includes("maps") ? "Google Maps" : "Google Search";
      } else if (referer.includes("facebook.com")) {
        return "Facebook";
      } else if (referer.includes("instagram.com")) {
        return "Instagram";
      } else {
        return "Others";
      }
    },
  },
};
</script>

<style>
#app {
  /* font-family: "Prata", serif, "Manrope", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  background-color: #082136;
}
</style>
