import api from "@/api";
import axios from "axios";

const clients = {
  namespaced: true,
  state: {
    subscribeClientInfo: {},
  },
  mutations: {
    setSubscribeClientInfo(state, payload) {
      state.subscribeClientInfo = payload;
    },
  },
  actions: {
    subscribeClient({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/r/${rootState.business.businessName}/v1/clients`, {
            phone_number: payload.phone_number,
            subscribe: payload.subscribe,
            source: payload.source,
            first_name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            birthday: payload.birthday,
          })
          .then(
            (response) => {
              commit("setSubscribeClientInfo", response.data);
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
  },
  getters: {
    getSubscribeClientInfo(state) {
      return state.clientInfo;
    },
  },
};

export default clients;
