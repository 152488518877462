<template>
  <q-card-section flat class="card-section-subscribe">
    <div class="text-subscribe" style="display: flex; justify-content: center">
      <h3 :style="{ ...getStyles.h3 }" class="h2-subscribe">Unlock VIP Perks: Exclusive Events and Discounts</h3>
    </div>
    <div>
      <q-btn @click="openModalOnClick" style="" class="btn-subscribe" no-caps>Join VIP Club</q-btn>
    </div>
  </q-card-section>
  <ModalSubscribers v-model="showModal" />
</template>
<script>
import ModalSubscribers from "../views/Home/modals/ModalSubscribers.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalSubscribers,
  },
  props: {
    fontsVariables: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("business", ["getBranchInfo", "getBranchConfig", "getBusinessInfo", "getBranchDetails", "getStyles"]),
  },
  methods: {
    openModalOnClick() {
      this.showModal = true;
    },
    openModal() {
      const modalCount = localStorage.getItem("modalCount") || 0;
      if (modalCount < 2) {
        this.showModal = true;
        localStorage.setItem("modalCount", modalCount + 1);
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    if (this.$route.path === "/") {
      setTimeout(() => {
        this.openModal();
      }, 15000);
    }
  },
};
</script>
<style lang="scss" scoped>
.h2-subscribe {
  line-height: normal;
  font-size: 2rem;
  margin-bottom: 0;
  font-family: Prata;
}

.card-section-subscribe {
  padding-top: 140px;
  padding-bottom: 140px;
}
.text-subscribe {
  font-size: 2rem !important;
  color: $accent;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 40px;
  max-width: 500px;
}
.btn-subscribe {
  font-family: Manrope;
  font-size: 1rem;
  background-color: $accent;
  color: $primary;
  text-align: center;
  width: 171px;

  height: 51px;
  border-radius: 0 !important;
}

@media screen and (max-width: 767px) {
  .text-subscribe {
    padding-left: 21px;
    padding-right: 21px;
    font-size: 1.25rem;
  }
}
</style>
