<template>
  <div class="section-faqs">
    <div class="div-expasion">
      <h2 :style="{ ...getStyles?.h2, color: getStyles.h2?.colorSecondary }" class="h2-faqs">Frequently Asked Questions</h2>
      <q-expansion-item v-for="item in items" :key="item.id" class="expansion-faqs">
        <template v-slot:header>
          <q-item-section class="q-item-section">
            <h5 :style="{ ...getStyles?.h5, color: getStyles.h5?.colorSecondary }" class="h5-faqs">
              {{ item.question }}
            </h5>
          </q-item-section>
        </template>
        <q-expansion-item-content>
          <p :style="{ ...getStyles?.p, color: getStyles.p?.colorSecondary }" class="p-faqs">{{ item.answer }}</p>
        </q-expansion-item-content>
      </q-expansion-item>
    </div>
  </div>
  <div class="fixed-menu">
    <ReserveBar />
  </div>
  <div class="fixed-call">
    <CallBar />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ReserveBar from "../layouts/ReserveBar.vue";
import CallBar from "../layouts/CallBar.vue";

export default {
  components: {
    CallBar,
    ReserveBar,
  },
  data() {
    return {
      title: "Frequently Asked Questions",
      description: "Do you have questions? We have answers! ",
      items: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTitle();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle();
    next();
  },
  methods: {
    fetchFaqs() {
      this.$store
        .dispatch("business/fetchFaqs")
        .then((response) => {
          this.items = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setTitle() {
      document.title = this.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", this.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", this.description);
        document.head.appendChild(newMeta);
      }
    },
  },
  computed: {
    ...mapGetters("business", ["getStyles"]),
  },
  mounted() {
    this.fetchFaqs();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.fixed-menu {
  display: none;
  height: 60px;
}
.fixed-call {
  display: none;
  height: 60px;
}
.expansion-faqs {
  ::v-deep .q-item__section--side {
    color: $primary;
  }
}

.p-faqs {
  font-family: Manrope;
  text-align: left;
  font-size: 1rem;
  margin: 0;
}
.div-expasion {
  padding-bottom: 150px;
}
.expansion-faqs {
  border-bottom: 1px solid $border;

  ::v-deep .q-item {
    padding-right: 0;
    padding-left: 0;
  }
}
.h2-faqs {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: Prata;
  color: $primary;
  text-align: center;
  padding-top: 117px;
  margin-bottom: 57px;
  margin-top: 0;
}
.h5-faqs {
  font-size: 1.0625rem;
  font-weight: 600;
  font-family: Manrope;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}
.section-faqs {
  background-color: $background;
  color: $primary;
  padding-left: 363px;
  padding-right: 363px;
  margin-top: 91px;
}
.label-container {
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .section-faqs {
    padding-left: 90px;
    padding-right: 90px;
    margin-top: 62px;
  }
}
@media screen and (max-width: 883px) {
  .section-faqs {
    padding-left: 21px;
    padding-right: 21px;
    margin-top: 62px;
  }
  .fixed-menu {
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    right: 0;
    border-top: solid #dee1e4 2px;
  }
  .fixed-call {
    left: 0;
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    border-top: solid #dee1e4 2px;
  }
}
</style>
