import api from "@/api";
import axios from "axios";

// import { param } from "jquery";

const menu = {
  namespaced: true,
  state: {
    scrollIntern: false,
    menuTime: [],
    allProducts: [],
    productsFiltered: [],
    foodConfig: [],
    foodState: [],
    isFiltered: false,
    onlyProducts: [],
    dateRequestServer: "",
    dateRequestCheck: "",
    productsBestSeller: [],
    menuNameParams: {},
  },

  actions: {
    // async fetchRestaurant(context) {
    //   const response = await axios.get(`${api}/restaurants/1`);
    //   context.commit("setRestaurant", response.data);
    // },
    async fetchProductsBestSeller({ commit, rootState }) {
      const response = await axios.get(`${api}/r/${rootState.business.businessName}/v1/product-details`);
      commit("setProductsBestSeller", response.data);
      return response;
    },
    async mostLessProducts({ rootState }, body) {
      if (body.client_id === null) {
        body.client_id = 200;
      }
      const response = await axios.post(`${api}/r/${rootState.business.businessName}/v1/query-products`, body);

      return response;
    },
    async fetchMenuTimeAll({ commit, rootState }) {
      const response = await axios.get(`${api}/r/${rootState.business.businessName}/v1/menu`);
      commit("setMenuTime", response.data);
    },
    async fetchMenuTime({ commit, rootState }, params) {
      const response = await axios.get(`${api}/r/${rootState.business.businessName}/v1/menu/menu_name`, {
        params,
      });

      const categories = response.data;
      const availableCategories = [];
      const unavailableCategories = [];

      for (let i = 0; i < categories.length; i++) {
        if (categories[i].products.length == 0) continue;
        if (categories[i].is_available) {
          availableCategories.push(categories[i]);
        } else {
          unavailableCategories.push(categories[i]);
        }
      }

      const reorderedCategories = availableCategories.concat(unavailableCategories);

      commit("setAllProducts", reorderedCategories);
      commit("setOnlyProducts", reorderedCategories);
      commit("setProductsFiltered", reorderedCategories);

      return response;
    },
    // async fetchAllProducts({ commit, rootState }) {
    //   const response = await axios.get(`${api}/r/${rootState.business.businessName}/v1/available-categories`); // tomar response.data, recorrerlo y llevar los NO dispobibles a lo ultimo

    //   const categories = response.data;
    //   const availableCategories = [];
    //   const unavailableCategories = [];

    //   for (let i = 0; i < categories.length; i++) {
    //     if (categories[i].products.length == 0) continue;
    //     if (categories[i].is_available) {
    //       availableCategories.push(categories[i]);
    //     } else {
    //       unavailableCategories.push(categories[i]);
    //     }
    //   }

    //   const reorderedCategories = availableCategories.concat(unavailableCategories);

    //   commit("setAllProducts", reorderedCategories);
    //   commit("setOnlyProducts", reorderedCategories);
    //   commit("setProductsFiltered", reorderedCategories);

    //   return response;
    // },
  },
  mutations: {
    setMenuNameParams(state, value) {
      state.menuNameParams = value;
    },
    setProductsBestSeller(state, value) {
      state.productsBestSeller = value;
    },
    setScrollIntern(state, value) {
      state.scrollIntern = value;
    },
    setRequestServer(state, data) {
      state.dateRequestServer = data;
    },
    setRequestCheck(state, data) {
      state.dateRequestCheck = data;
    },

    setProductsFiltered(state, data) {
      state.productsFiltered = data;
    },
    setAllProducts(state, data) {
      state.allProducts = data;
    },
    filterByText(state, payload) {
      if (payload === "") {
        state.isFiltered = false;
        state.productsFiltered = JSON.parse(JSON.stringify(state.allProducts));
      } else {
        state.isFiltered = true;
        state.productsFiltered = JSON.parse(JSON.stringify(state.allProducts));
        payload = payload.toLowerCase();
        for (let index = 0; index < state.productsFiltered.length; index++) {
          state.productsFiltered[index].products = state.productsFiltered[index].products.filter((product) => {
            return product.name.toLowerCase().indexOf(payload) > -1;
          });
        }
      }
    },
    setOnlyProducts(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        const category = payload[index];
        for (let index2 = 0; index2 < category.products.length; index2++) {
          const product = category.products[index2];
          state.onlyProducts.push(product);
        }
      }
    },
    setFoodConfig(state, payload) {
      state.foodConfig = payload;
    },
    setReadyForPay(state) {
      state.readyForPay = true;
    },

    setMenuTime(state, payload) {
      state.menuTime = payload;
    },
  },
  getters: {
    getMenuNameParams(state) {
      return state.menuNameParams;
    },

    getProductsBestSeller(state) {
      return state.productsBestSeller;
    },
    getMenuTime(state) {
      return state.menuTime;
    },
    getRequestServer(state) {
      return state.dateRequestServer;
    },
    getRequestCheck(state) {
      return state.dateRequestCheck;
    },

    getAllProducts(state) {
      return state.allProducts;
    },
    getFilteredCategories(state) {
      const categories = [];

      for (let index = 0; index < state.productsFiltered.length; index++) {
        const { id, name, description, status, products, is_available } = state.productsFiltered[index];
        const category = {
          id,
          name,
          description,
          status,
          products,
          is_available,
        };
        category.products.sort((a, b) => a.position - b.position);

        category.ref = category.name.replace(/[\s/]+/g, "");
        categories.push(category);
      }

      return categories;
    },
    getProductsFiltered(state) {
      return state.productsFiltered;
    },
    getFoodConfig(state) {
      return state.foodConfig;
    },
    getReadyForPay(state) {
      return state.readyForPay;
    },
    getIsFiltered(state) {
      return state.isFiltered;
    },

    getOnlyProducts(state) {
      return state.onlyProducts;
    },
  },
};
export default menu;
