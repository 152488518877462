<template>
  <q-card-section class="section-cousine">
    <div class="div-titles" style="">
      <div class="title-card">
        <h2 :style="{ ...getStyles.h2, color: getStyles.h1?.colorSecondary }" class="h4-cousine" style="">Our Cuisine</h2>
      </div>
      <div class="title-card-p">
        <router-link class="p-cousine" :to="{ name: 'menu' }">
          Explore menu <q-icon name="chevron_right" style="font-size: 24px"></q-icon>
        </router-link>
      </div>
    </div>
    <div class="cousine" v-if="!loadedProducts">
      <q-skeleton v-for="i in 3" :key="i" class="cousine-card-skeleton" />
    </div>
    <div class="cousine">
      <q-card
        flat
        class="cousine-card"
        v-for="(product, index) in bestSellerProducts"
        :key="index"
        @click="openModalWithProduct(product)"
      >
        <img class="img-products" :src="product.image" style="" alt="Products best seller Avenu Blu" />

        <card-section class="cousine-info">
          <h6 :style="{ ...getStyles?.h6 }" class="text-h6">
            {{ product.name }} <q-icon name="chevron_right" style="font-size: 24px"></q-icon>
          </h6>
        </card-section>
      </q-card>
    </div>
    <div class="title-card-p-mb">
      <router-link class="p-cousine" :to="{ name: 'menu' }" style="">
        Explore menu <q-icon name="chevron_right" style="font-size: 24px"></q-icon>
      </router-link>
    </div>
  </q-card-section>
  <ModalProduct v-model="showModal" :currentProduct="selectedProduct" />
</template>
<script>
import ModalProduct from "../Menu/modals/ModalProduct.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      selectedProduct: null,
      skeletonCount: 3,
    };
  },
  components: {
    ModalProduct,
  },
  props: {
    bestSellerProducts: {
      type: Array,
      required: true,
    },
    loadedProducts: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("business", ["getReviews", "getStyles"]),
  },

  mounted() {},
  methods: {
    openModalWithProduct(product) {
      this.selectedProduct = product;
      this.showModal = true;
    },
    goToMenu() {
      this.$router.push({ name: "menu" }); // Usa la ruta correcta para tu componente de menú
    },
  },
};
</script>
<style lang="scss" scoped>
.div-titles {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px;
  height: 44px;
}
.title-card-p-mb {
  display: none;
}
.section-cousine {
  display: flex;
  flex-direction: column;
  max-width: 1067px;
  color: $primary;
  padding: 0 !important;
  width: -webkit-fill-available;
}

.title-card-p {
  display: flex;
  align-items: center; /* Alinea los elementos internos verticalmente al centro */
}
.title-card-p {
  .p-cousine {
    color: $primary;
    text-decoration: none;
    margin-bottom: 0 !important;
    font-family: Manrope;
    font-size: 1.1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: underline;
    }
    &:focus {
      background-color: transparent;
      border: 3px solid $primary;
      text-decoration: underline;
    }
  }
}
.h4-cousine {
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  font-size: 2rem;
  font-family: Prata;
}
.img-products {
  height: 437px;

  object-fit: cover;
}

.text-subtitle2 {
  font-size: 1rem;
  font-family: Manrope;
  padding-bottom: 16px;
  padding-left: 26px;
  padding-right: 26px;
}
.text-h6 {
  margin: 0;
  font-family: Manrope;
  font-size: 1rem;
  bottom: 0;
  left: 0;
  // // background: linear-gradient(180deg, rgb(2 2 2 /5%) 5%, rgb(2 2 2/80%) 80%, rgb(2 2 2/100%) 100%);
  background: linear-gradient(180deg, rgba(2, 2, 2, 0) 5%, rgba(2, 2, 2, 0.8) 80%, #020202);
  text-align: left;
  position: absolute;
  width: 100%;
  height: 74px;
  padding-left: 31px;
  padding-right: 31px;
  color: $accent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cousine-card {
  width: 100%;
  min-height: 340px;
  border-radius: 0 !important;
  background: transparent;
}
.cousine-card-skeleton {
  width: 100%;
  min-height: 340px;
  border-radius: 0 !important;
  height: 437px;
}
.title-card {
  display: flex;
  justify-content: space-between; /* Centra horizontalmente */
  align-items: center;
}
.cousine {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  text-align: left;
  padding-bottom: 170px;
}

@media screen and (max-width: 1024px) {
  // .cousine-card {
  //   width: 204px;
  //   height: 368px;
  // }
}
@media screen and (max-width: 910px) {
  .cousine {
    padding-bottom: 0;
  }
  .text-h6 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .cousine {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    text-align: left;
  }
}

@media screen and (max-width: 796px) {
  .icon-arrow {
    margin-top: 7px;
  }
  .div-titles {
    padding-top: 0;
    margin-bottom: 0;
    height: 70px;
  }
  .title-card-p {
    display: none;
  }
  .title-card-p-mb {
    text-align: left;
    display: block;
    margin-top: 29px;
    display: flex;
    padding-bottom: 36px;
    .p-cousine {
      color: $primary;
      text-decoration: none;
      font-size: 1.1rem;
      font-family: Manrope;
      border: none;
      cursor: pointer;
      background: transparent;
      padding: 0;

      &:hover {
        text-decoration: underline;
        opacity: 0.8;
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.4);
        text-decoration: underline;
      }
      &:focus {
        background-color: transparent;
        border: 3px solid $primary;
        text-decoration: underline;
      }
    }
  }
  // .text-subtitle2 {
  //   padding-left: 0px;
  //   padding-right: 0px;
  // }
  // .text-h6 {
  //   padding-left: 0px;
  //   padding-right: 0px;
  // }

  .title-card {
    order: 0;

    .h4-cousine {
      font-size: 1.5rem;
      text-align: left;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  // .cousine {
  //   order: 1; /* Las tarjetas de los productos van primero */
  // }
  // .title-card-p {
  //   order: 2;
  //   text-align: left;
  //   padding-top: 29px;
  //   padding-bottom: 79px;

  //   .p-cousine {
  //     padding-bottom: 0;
  //   }
  // }
  // .h4-cousine {
  //   margin-bottom: 0 !important;
  //   margin-top: 0 !important;
  //   padding-bottom: 32px;
  // }

  // .text-h6 {
  //   padding-bottom: 11px;
  //   padding-top: 0;
  // }
  // .img-products {
  //   height: 120px;
  //   width: 116px;
  // }
  // .cousine-card {
  //   width: 330px;
  //   height: 120px;
  //   display: flex;
  //   flex-direction: row-reverse;
  // }
  // .cousine-info {
  //   padding-top: 14px;
  //   padding-left: 11px; /* Agregar un relleno para separar el texto de la imagen */
  // }
  .cousine-card-skeleton {
    width: 100%;
    border-radius: 0 !important;
  }
  .cousine-card {
    width: 100%;
    border-radius: 0 !important;
  }
  .cousine {
    display: grid;
    grid-template-columns: 1fr;
    gap: 18px;
    text-align: left;
  }
}
</style>
