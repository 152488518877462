<template>
  <div class="div-events">
    <div>
      <div class="title-card">
        <h1 :style="{ ...getStyles.h1, color: getStyles.h1?.colorSecondary }" class="h4-events">Upcoming Events</h1>
        <!-- <p class="p-events">Come join our special events to vibe with the best</p> -->
      </div>
    </div>
    <div class="events">
      <div class="event-card" v-for="(event, index) in eventsHome" :key="index" @click="learnMore(event.id)">
        <div class="container-img-event">
          <img :src="event.image" class="img-event" alt="img-event" loading="lazy" />
        </div>

        <div class="section-event">
          <div class="div-info">
            <h2 :style="{ ...getStyles.h2, color: getStyles.h2?.colorSecondary }" class="text-h6">{{ event.title }}</h2>
            <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" class="text-subtitle2">{{ event.description }}</p>
            <div class="info-icons-schedule">
              <div class="info-icon-date">
                <q-icon class="icon-event" name="event" size="24px"></q-icon>
                <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" style="margin: 0">{{ event.schedule }}</p>
              </div>
              <div class="info-icon-hour">
                <q-icon class="icon-schedule" name="schedule" size="24px"></q-icon>
                <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" style="margin: 0">
                  {{ event.start_hour }} - {{ event.end_hour }}
                </p>
              </div>
              <div class="info-icons-schedule-arrow">
                <div class="schedule">
                  <q-icon class="icon-schedule" name="schedule" size="24px"></q-icon>
                  <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" style="margin: 0">
                    {{ event.start_hour }} - {{ event?.end_hour }}
                  </p>
                </div>
                <div>
                  <q-icon class="learn-more" name="chevron_right" size="24px"></q-icon>
                </div>
              </div>
            </div>
            <div class="title-card-p">
              <router-link
                :to="{ name: 'events-detail', params: { eventId: event.id } }"
                class="p-events"
                style="margin-bottom: 0 !important"
              >
                Explore events <q-icon name="chevron_right" style="font-size: 24px"></q-icon>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fixed-contact">
    <ContactUs />
  </div>
  <div class="fixed-menu">
    <ReserveBar />
  </div>
  <div class="fixed-call">
    <CallBar />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContactUs from "../layouts/ContactUs.vue";
import { parseISO, format } from "date-fns";
import { en } from "date-fns/locale";
import ReserveBar from "../layouts/ReserveBar.vue";
import CallBar from "../layouts/CallBar.vue";
export default {
  components: {
    ContactUs,
    CallBar,
    ReserveBar,
  },
  data() {
    return {
      title: "Events",
      description:
        "Join us every week for live entertainment, tastings, and an amazing brunch or dinner celebration experience with a live DJ for your enjoyment!",
      eventsHome: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTitle();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle();
    next();
  },
  computed: {
    ...mapGetters("business", ["getStyles"]),
  },
  methods: {
    learnMore(eventId) {
      this.$store.dispatch("business/fetchEventsDetail", eventId).then(() => {
        this.$router.push({ name: "events-detail", params: { eventId } });
      });
    },

    setTitle() {
      document.title = this.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", this.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", this.description);
        document.head.appendChild(newMeta);
      }
    },

    fetchEvents() {
      this.$store.dispatch("business/fetchEvents").then((resp) => {
        this.eventsHome = resp;
      });
    },

    formatDate(date) {
      return format(parseISO(date), "EEE, LLLL d, yyyy", { locale: en });
    },
  },
  created() {
    // const business = process.env.BUSINESS;
    // const id = process.env.BRANCH;
    // this.$store.commit("business/setBusinessName", business);
    // this.$store.commit("business/setIdBranch", id);
  },
  mounted() {
    this.fetchEvents();
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.schedule {
  display: flex;
  justify-content: start;
}
.fixed-menu {
  display: none;
  height: 60px;
}
.fixed-call {
  display: none;
  height: 60px;
}
.info-icons-schedule-arrow {
  display: none;
}
.p-events {
  text-decoration: none;
  color: $primary;
  margin-bottom: 0 !important;
  padding-left: 0;
  font-family: Manrope;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    text-decoration: underline;
  }
  &:focus {
    background-color: transparent;
    border: 3px solid $primary;
    text-decoration: underline;
  }
}
.container-img-event {
  width: 528px;
  height: 329px;
}
.h4-events {
  padding-top: 56px;
  font-size: 1.5rem;
  font-family: Prata;
}
.icon-schedule {
  margin-right: 10px;
}
.icon-event {
  margin-right: 10px;
}
.fixed-contact {
  position: fixed;
  height: 54px;
  top: 88px;
  width: 100%;
  z-index: 1000;
}
.p-events {
  font-size: 1.25rem;
  font-family: Manrope;
  color: $primary;
  margin-bottom: 53px;
}
.div-info {
  padding-left: 62px;
}
.section-event {
  display: flex;
  align-items: center;
}

.div-events {
  background-color: $background;
  padding-left: 140px;
  padding-right: 140px;
  margin-top: 144px;
  padding-bottom: 100px;
}
.info-icons-schedule {
  display: flex;
  flex-direction: column;
}
.img-event {
  width: 528px;
  height: 100%;

  object-fit: cover;
  border-radius: 0 !important;
}

.title-card {
  text-align: left;
  color: $primary;
}

.h4-events {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 64px;
  color: $primary;
  font-size: 2.5rem;
  font-family: Prata;
  // padding-top: 220px;
  margin: auto;
  max-width: 1300px;
}
.info-icon-hour {
  font-size: 1.125rem;
  font-family: Manrope;
  padding-bottom: 32px;
  color: $primary;
  display: flex;
  justify-content: start;
}
.btn-learn {
  text-align: center;
  width: 118px;
  height: 43px;
  border-radius: 0 !important;
  font-size: 1rem;
  font-family: Manrope;
  color: $accent;
  background-color: $secondary;
  border: none;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    color: $accent;
    background-color: $secondary;
  }

  &:focus {
    border: 3px solid #005cfc;
  }
}
.info-icon-date {
  font-size: 1.125rem;
  font-family: Manrope;
  padding-bottom: 15px;
  color: $primary;
  display: flex;
  justify-content: start;
}
.text-subtitle2 {
  color: $primary;
  font-size: 1rem;
  font-family: Manrope;
  padding-bottom: 32px;
  margin: 0;
}
.text-h6 {
  color: $primary;
  font-size: 1.5rem;
  font-family: Prata;
  padding-bottom: 4px;
}
.event-card {
  display: flex;
  border: 1px solid $border;
  border-radius: 0 !important;
}

.events {
  display: grid;
  grid-template-columns: 1fr;
  gap: 61px;
  text-align: left;
  margin: auto;
  max-width: 1300px;
}
@media screen and (max-width: 1024px) {
  .div-info {
    padding-left: 61px;
  }
  .h4-events {
    padding-top: 66px;
  }
  .div-events {
    background-color: $background;
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 144px;
    padding-bottom: 100px;
  }
  .info-icons-schedule {
    display: flex;
    flex-direction: column;
  }
  .img-event {
    width: 419px;
    height: 310px;
    object-fit: cover;
    border-radius: 0 !important;
  }
  .info-icon-date {
    font-size: 1rem;
  }
  .info-icon-hour {
    font-size: 1rem;
  }
  .container-img-event {
    width: 419px;
    height: 310px;
  }
}
@media screen and (max-width: 883px) {
  .fixed-menu {
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    right: 0;
    border-top: solid #dee1e4 2px;
  }
  .fixed-call {
    left: 0;
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    border-top: solid #dee1e4 2px;
  }
  .info-icon-hour {
    display: none;
  }
  .title-card-p {
    display: none;
  }
  .info-icons-schedule-arrow {
    display: block;
    display: flex;
    justify-content: space-between;
    font-family: Manrope;
    color: $primary;
    font-size: 1rem;
  }
  .container-img-event {
    max-width: 388px;
    width: 100%;
    height: 196px;
  }
  .p-events {
    margin-bottom: 35px;
  }
  .h4-events {
    padding-top: 54px;
    padding-bottom: 18px;
  }
  .text-h6 {
    padding-bottom: 5px;
  }
  .info-icon-date {
    padding-bottom: 12px;
  }
  .info-icon-hour {
    text-align: end;
    padding-bottom: 0;
  }
  .fixed-contact {
    position: fixed;
    height: 68px;
    top: 59px;
    width: 100%;
    z-index: auto;
  }
  .text-subtitle2 {
    padding-top: 2px;
    padding-bottom: 21px;
  }
  .events {
    gap: 42px;
  }

  .info-icons-schedule {
    display: flex;
    flex-direction: column;
  }
  .div-events {
    background-color: $background;
    padding-left: 21px;
    padding-right: 21px;
    margin-top: 128px;
  }
  .section-event {
    display: flow;
  }

  .event-card {
    display: flex;
    flex-direction: column;
  }
  .img-event {
    max-width: 388px;
    width: 100%;
    height: 196px;
    object-fit: cover;
    border-radius: 0 !important;
  }
  .div-info {
    padding-left: 0;
    padding-top: 31px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 25px;
  }
}
</style>
