<template>
  <div class="custom-tabs">
    <p :style="{ ...getStyles.p }" class="p-contact">
      Do you need to reserve a private event? <a href="tel:8132501655" class="a-contact">Call us</a>
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    fetchBranchInfo() {
      this.$store.dispatch("business/fetchBranchInfo");
      this.$store.dispatch("business/fetchBusinessInfo");
    },
    fetchBranchConfig() {
      this.$store.dispatch("business/fetchBranchConfig");
      // .then((resp) => {
      //   if (resp.status == 200) {
      //     // const styles = resp.data[0];
      //     // setCssVar("secondary", styles?.color);
      //     // setCssVar("card_stroke", styles?.card_stroke);
      //     // setCssVar("instagram", styles?.instagram);
      //     // setCssVar("review", styles?.review || "#DBB244");
      //     // setCssVar("bg-options", styles?.background_options);
      //     // setCssVar("bg-search", styles?.background_search);
      //     // setCssVar("bg-products-item", styles?.background_products_item);
      //     // setCssVar("bg-products", styles?.background_products);
      //     // setCssVar("bg-modal", styles?.background_modal);
      //     // setCssVar("bg-tabs", styles?.background_tabs);
      //     console.log("todo good");
      //   }
      // });
    },
    fetchBranchDetails() {
      this.$store.dispatch("business/fetchBranchDetails");
      // .then((resp) => {
      //   if (resp.status == 200) {
      //     // const styles = resp.data || resp.detail || {};
      //     // setCssVar("bg-color", styles?.detail?.color_background); //esto es el background
      //     // setCssVar("disable-btn", styles?.detail?.disable_buttons); // esto para btn desactivados
      //     // setCssVar("body-color", styles?.detail?.body_color); // esto para los subtitulos
      //     // setCssVar("body-font", styles?.detail?.body_type);
      //     // setCssVar("title-font", styles?.detail?.title_type);
      //     // setCssVar("bg-nav", styles?.detail?.background_navigation);
      //     // setCssVar("bg-contrast", styles?.detail?.background_contrast);
      //     console.log("todo good");
      //   }
      // });
    },
  },
  computed: {
    ...mapGetters("business", ["getBranchInfo", "getBranchConfig", "getBusinessInfo", "getBranchDetails", "getStyles"]),
  },
  // created() {
  //   this.fetchBranchInfo();
  //   this.fetchBranchConfig();
  //   this.fetchBranchDetails();
  // },
  // mounted() {
  //   this.fetchBusinessInfo();
  // },
};
</script>

<style lang="scss" scoped>
.p-contact {
  margin-bottom: 0;
}
.a-contact {
  color: $accent;
}
.custom-tabs {
  align-items: center;
  color: $accent;
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
  font-family: Manrope;
  height: 100%;
  background-color: $secondary;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
</style>
