<template>
  <nav :class="{ scrolled: backgroundVisibility }">
    <q-layout class="navbar" view="hHh LpR fFf">
      <div class="toolbar">
        <q-btn aria-label="open menu" class="sidebar-btn" dense flat round icon="menu" @click="toggleLeftDrawer" />
        <div class="logo-container">
          <a href="http://www.avenueblu.com/" rel="noopener noreferrer">
            <q-avatar style="width: 96px">
              <q-img src="@/assets/images/logoavenue.webp" width="93px" height="36px" alt="Logo de Avenue Blu" />
            </q-avatar>
          </a>
        </div>

        <div class="buttons-container">
          <router-link
            v-for="tab in tabs"
            :key="tab.id"
            :to="tab.route"
            class="custom-buttons-nav"
            :class="{ 'selected-button-nav': activeTab(tab) }"
          >
            {{ tab.label }}
          </router-link>
          <div class="reserve-btn-nav">
            <a :href="getBranchConfigReserve" class="custom-button-nav" target="_blank">Reserve</a>
          </div>
        </div>
      </div>

      <q-drawer class="sidebar" show-if-above v-model="leftDrawerOpen" side="right" bordered breakpoint="700">
        <div class="drawer-header" style="height: 70px">
          <div class="close-logo-container">
            <q-btn
              dense
              flat
              round
              icon="close"
              style="position: absolute; top: 13px; left: 20px; color: white; padding-right: 16px"
              @click="leftDrawerOpen = false"
              class="close-btn"
            />
            <div class="logo-container">
              <a href="http://www.avenueblu.com/" rel="noopener noreferrer">
                <q-avatar style="width: 96px; position: absolute; top: 5px; left: 53px">
                  <q-img src="@/assets/images/logoavenue.webp" width="93px" height="36px" alt="Logo de Avenue Blu" />
                </q-avatar>
              </a>
            </div>
          </div>
        </div>
        <div class="buttons-container-drawer">
          <router-link
            v-for="tab in tabs"
            :key="tab.id"
            :to="tab.route"
            class="custom-buttons-nav"
            :class="{ 'selected-button-nav': activeTab(tab) }"
          >
            {{ tab.label }}
          </router-link>
        </div>
      </q-drawer>
    </q-layout>
  </nav>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const isScrolled = ref(false);
    const leftDrawerOpen = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tabs = [
      {
        id: 2,
        label: "Welcome",
        route: "/",
      },
      {
        id: 3,
        label: "Menu",
        route: "menu",
      },
      {
        id: 4,
        label: "Events",
        route: "events",
      },
      // {
      //   id: 5,
      //   label: "Atmosphere",
      //   route: "atmosphere",
      // },
      {
        id: 6,
        label: "FAQs",
        route: "faqs",
      },
      {
        id: 7,
        label: "Our Story",
        route: "story",
      },
    ];
    const toggleLeftDrawer = () => {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    };
    const store = useStore();
    const getBranchConfig = computed(() => store.getters["business/getBranchConfig"]);
    const getBranchConfigReserve = computed(() => store.getters["business/getBranchConfigReserve"]);

    const activeTab = (tab) => {
      if (route.path === tab.route) return true;
      if (route.name === tab.route) return true;
    };

    const goToReservationPage = () => {
      const reserveLink = getBranchConfig.value.reserve_link; // Asegúrate de que esta ruta sea correcta según tu store
      if (reserveLink) {
        window.open(reserveLink, "_blank");
      }
    };

    const backgroundVisibility = computed(() => {
      if (route.path === "/") {
        return !isScrolled.value;
      }
      return false;
    });

    const navigateToTab = (tab) => {
      router.push({ name: tab.route }).then(() => {
        // Esto se llama después de que la navegación ha finalizado
        window.scrollTo(0, 0); // Forzar el scroll hacia arriba
      });
    };

    // const navigateToMenu = (tab) => {
    //   router.push({ name: tab.route }).then(() => {
    //     // Asegura que el scroll se reinicia después de la navegación.
    //     nextTick(() => {
    //       window.scrollTo(0, 0);
    //     });
    //   });
    // };

    // const isScrolledIntern = computed(() => store.state.menu.scrollIntern);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      isScrolled.value = scrollPosition > 0;

      const navBar = document.querySelector("nav");

      if (scrollPosition === 0) {
        navBar.classList.remove("scrolled");
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll, { passive: true });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll, { passive: true });
    });

    return {
      isScrolled,
      tabs,
      // isScrolledIntern,
      leftDrawerOpen,
      toggleLeftDrawer,
      goToReservationPage,
      // navigateToMenu,
      navigateToTab,
      backgroundVisibility,
      activeTab,
      getBranchConfigReserve,
    };
  },
};
</script>

<style lang="scss">
.close-logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-buttons-nav {
  text-decoration: none;
  background-color: transparent;
  border: 0;
  color: $accent;
  font-family: Manrope;
  font-size: 1.1875rem;
  font-weight: 600;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: $accent;
    text-decoration: none;
  }

  &:focus-visible {
    border: 2px solid $accent;
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
    background-color: transparent;
  }
}
.sidebar-btn {
  display: none !important;
  color: $accent !important;

  .q-icon {
    font-size: 40px !important;
  }

  padding-top: 5px !important;
  padding-right: 16px !important;
}
.q-drawer--right {
  display: none; /* Oculta el botón y el drawer por defecto */
}
.navbar {
  height: 90px !important;
}
.q-layout {
  min-height: 20px !important;
  background-color: transparent !important;
}
.buttons-container-drawer {
  font-family: Manrope;
  font-size: 1rem;
  color: $accent;
}
.buttons-container .selected-button-nav {
  text-decoration: underline !important;
}
.buttons-container-drawer .selected-button-nav {
  text-decoration: underline !important;
}
.reserve-btn-nav {
  font-family: Manrope;
  text-align: center;
  width: 118px;
  height: 37px;
  margin-left: 2px;
}

.buttons-container {
  gap: 24px;
  display: flex;
  // justify-content: center;
  justify-content: end;
  width: 100%;
  max-width: 717px;
}

.toolbar {
  color: $accent;
  height: 90px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 140px;
  padding-right: 140px;
  font-size: 1rem;
  font-family: Manrope;
}

.logo-container {
  justify-content: flex-start;
}

/* Estilos SCSS aquí */
.custom-button-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $primary;
  background-color: $accent;
  font-size: 1rem;
  border-radius: 0;
  width: 100%;
  height: 100%;
  border: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    color: $primary;
    background-color: $accent;
  }
  &:focus {
    border: 2px solid $accent;
    background-color: black;
    color: $accent;
  }
}

.scrolled {
  background-color: transparent; /* Color de fondo cuando se hace scroll */
  /* Agregar otros estilos que desees */
  transition: none;
  border-bottom: none;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  transition: background-color 0.3s ease;
  background-color: $primary; /* Color de fondo cuando se hace scroll */
  border-bottom: solid 2px $accent;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

nav li {
  cursor: pointer;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: $accent;
}
@media screen and (max-width: 1024px) {
  .toolbar {
    color: $accent;
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
    font-size: 1rem;
    font-family: Manrope;
  }
  .buttons-container {
    gap: 12px;
  }
}
@media (min-width: 1025px) and (max-width: 1070px) {
  .buttons-container {
    gap: 6px;
  }
}

@media screen and (max-width: 913px) {
  .navbar {
    height: 60px !important;
  }
  .buttons-container-drawer {
    gap: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .buttons-container {
    display: none;
  }
  .toolbar {
    max-width: 775px;
    min-width: 345px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left; /* Esto separa el logotipo y el botón del menú */
    padding: 0 1rem; /* Ajusta el padding como sea necesario */
    // Otros estilos...
    padding-left: 21px;
    padding-right: 21px;
    height: 60px;
  }

  .sidebar-btn {
    /* Asegúrate de que hay espacio a la derecha del botón */
    display: block !important;
  }
  .q-drawer--right {
    display: block; /* Oculta el botón y el drawer por defecto */
    height: 100dvh;
    transform: translateX(0px) !important;
  }
  .q-drawer {
    // top: 68px !important;
    width: 100% !important;
    background: $primary !important;
    .q-drawer__content {
      // padding: 138px 0px 0px 30px !important;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
