<template>
  <q-card-section class="card-section-reviews">
    <h2 :style="{ ...getStyles.h2, color: getStyles.h2?.colorSecondary }" class="h4-reviews" style="">Our Guests Reviews</h2>
    <div class="reviews">
      <q-card flat class="" v-for="(review, index) in allReviews" :key="index" style="background: none">
        <div style="display: flex; justify-content: center; padding-bottom: 18px">
          <img
            loading="lazy"
            alt="profile"
            style="display: flex; width: 54px; height: 54px; border-radius: 50%"
            :src="review.image"
          />
        </div>

        <q-card-section style="padding-top: 0 !important; padding-bottom: 0 !important">
          <h6 :style="{ ...getStyles.h6, color: getStyles.h6?.colorSecondary }" class="text-name-review">{{ review.name }}</h6>

          <!-- <div class="star-section">
            <q-icon name="star" size="md" class="icon-star" aria-label="1 estrella"></q-icon>
            <q-icon name="star" size="md" class="icon-star" aria-label="2 estrellas"></q-icon>
            <q-icon name="star" size="md" class="icon-star" aria-label="3 estrellas"></q-icon>
            <q-icon name="star" size="md" class="icon-star" aria-label="4 estrellas"></q-icon>
            <q-icon name="star" size="md" class="icon-star" aria-label="5 estrellas"></q-icon>
          </div> -->

          <q-rating v-model="starValue" :max="5" color="#e7ba63" size="1.25rem" style="color: #e7ba63 !important">
            <template v-slot:default>
              <template v-for="value in starValues" :key="value">
                <q-icon name="star" class="icon-star" style="display: flex; align-items: center; justify-content: center">
                  {{ value }}
                </q-icon>
              </template>
            </template>
          </q-rating>

          <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" style="margin: 0" class="text-subtitle2">
            {{ review.review }}
          </p>
        </q-card-section>
      </q-card>
    </div>
  </q-card-section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    allReviews: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      starValue: 5,
    };
  },

  computed: {
    ...mapGetters("business", ["getReviews", "getStyles"]),
    starValues() {
      return [1, 2, 3, 4, 5]; // Valores para cada estrella
    },
  },
  methods: {
    applyFontsVariables() {
      const elements = document.getElementsByClassName("text-name-review");
      if (this.fontsVariables[1]?.device_type === "Desktop") {
        const elementsArray = Array.from(elements);
        elementsArray.forEach((element) => {
          element.style.fontFamily = this.fontsVariables[0].h6_font_name;
          element.style.fontSize = this.fontsVariables[0].h6_font_size + "rem";
          element.style.fontWeight = this.fontsVariables[0].h6_font_weight;
          element.style.color = this.fontsVariables[0].h6_color.accent;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.h4-reviews {
  color: $primary;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 62px;
  font-size: 2rem;
  font-family: Prata;
}
.card-section-reviews {
  max-width: 1276px;
  margin: auto;
}
.icon-star {
  color: #e7ba63;
}
.text-subtitle2 {
  color: $primary;
  padding-top: 17px;
  font-size: 1rem;
  font-family: Manrope;
}
.text-name-review {
  color: $primary;
  padding-bottom: 14px;
  font-size: 1rem;
  font-family: Prata;
  margin: 0;
}
.star-section {
  margin-top: 3px;
}

.reviews {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
@media screen and (max-width: 767px) {
  .h4-reviews {
    text-align: left;
  }
  .card-review {
    padding-bottom: 51px;
  }
  .reviews {
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
  }
}
</style>
