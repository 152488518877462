<template>
  <q-card flat class="card-info">
    <div class="info-btns">
      <a href="http://www.avenueblu.com/" rel="noopener noreferrer">
        <q-avatar style="width: 81px; height: 64px; margin-top: 37px; margin-bottom: 29px">
          <img src="@/assets/images/avenue.webp" style="width: 81px; height: 64px" loading="lazy" alt="Logo de Avenue Blu" />
        </q-avatar>
      </a>
      <div class="icons">
        <a :href="getBranchConfig?.instagram_link" target="_blank" rel="noopener noreferrer"
          ><img class="q-mx-sm" src="@/assets/icons/ig-icon.svg" alt="ig-icon" style="width: 24px; height: 24px" />
        </a>

        <a :href="getBranchConfig?.yelp" target="_blank" rel="noopener noreferrer">
          <img class="q-mx-sm" src="@/assets/icons/bi_yelp.svg" alt="yelp-icon" style="width: 24px; height: 24px" />
        </a>
      </div>
      <div class="btn-reserve">
        <a class="custom-btn-footer" target="_blank" :href="getBranchConfigReserve">Reserve</a>
      </div>
    </div>
    <div class="info-container">
      <div class="info-column">
        <div>
          <h6 :style="{ ...getStyles?.h6 }" class="h6-restaurant">AvenueBlu</h6>
          <p :style="{ ...getStyles.p }" class="p-restaurant p" style="margin-bottom: 0 !important">
            122 S. Howard Ave. Tampa, FL, US <br />
            info@avenueblu.com <br /><a class="a-tel" href="tel:+18132501655">(813) 250-1655</a>
          </p>
        </div>
        <div>
          <h3 class="h6-restaurant">Hours</h3>
          <p :style="{ ...getStyles.p }" class="p-restaurant p" style="margin-bottom: 0 !important">
            Wednesday: 05:00 pm - 10:00 pm<br />
            Thursday: 05:00 pm - 10:00 pm<br />Friday: 05:00 pm - 12:00 am<br />Saturday: 01:00 pm - 12:00 am <br />
            Sunday: 01:00 pm - 10:00 pm
          </p>
        </div>
      </div>
      <div class="info-columns">
        <div class="div-columns">
          <h6 :style="{ ...getStyles?.h6 }" class="h6">About us</h6>
          <!-- <button @click="goToAboutUs" class="p-div">Our Story</button> -->
          <a :href="getBranchConfigCareers" style="margin-bottom: 8px" class="p-div">Careers</a>
          <router-link :to="{ name: 'events' }" class="p-div">Events</router-link>
          <!-- <button @click="goToAtmosphere" class="p-div">Atmosphere</button> -->
        </div>
        <div class="div-columns">
          <h6 :style="{ ...getStyles?.h6 }" class="h6">Menus</h6>
          <!-- <button
            v-for="(menuItem, index) in menuNameOrder"
            :key="index"
            @click="fetchMenu(menuItem.menuName, index)"
            class="p-div"
          >
            {{ menuItem.menuName }}
          </button> -->
          <router-link
            :to="{ name: 'menu-name', params: { menuName: 'Dinner', position: 0 } }"
            style="margin-bottom: 8px"
            class="p-div"
            >Dinner</router-link
          >
          <router-link
            :to="{ name: 'menu-name', params: { menuName: 'Brunch', position: 1 } }"
            style="margin-bottom: 8px"
            class="p-div"
            >Brunch</router-link
          >
          <router-link :to="{ name: 'menu-name', params: { menuName: 'Happy Hour', position: 2 } }" class="p-div"
            >Happy Hour</router-link
          >
        </div>
        <div class="div-columns">
          <h6 :style="{ ...getStyles?.h6 }" class="h6">Help</h6>
          <!-- <button class="p-div">Privacy Policy</button>
          <button @click="goToParking" class="p-div">Accessibility</button> -->
          <router-link :to="{ name: 'faqs' }" class="p-div">FAQS</router-link>
        </div>
      </div>
    </div>
    <div class="text-payfud">
      <p class="p-footer" style="margin-bottom: 0 !important">
        © 2024 <a href="http://www.avenueblu.com/" style="color: white" target="_blank">AvenueBlu</a> Powered by
        <a href="https://payfud.com/" style="color: white" target="_blank">Payfud</a>
      </p>
    </div>
  </q-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      careersLink: "",
      menuNameOrder: {},
    };
  },
  methods: {
    goToLinkCarers() {
      this.careersLink = this.getBranchConfig?.careers;
      if (this.careersLink) {
        window.open(this.careersLink, "_blank");
      }
    },

    goToAboutUs() {
      this.$router.push({ name: "aboutus" }); // Usa la ruta correcta para tu componente de menú
    },
    goToAtmosphere() {
      this.$router.push({ name: "atmosphere" }); // Usa la ruta correcta para tu componente de menú
    },
    goToEvents() {
      this.$router.push({ name: "events" }); // Usa la ruta correcta para tu componente de menú
    },
    goToReservationPage() {
      const reserveLink = this.getBranchConfig?.reserve_link;
      if (reserveLink) {
        window.open(reserveLink, "_blank");
      }
    },
    fetchBusinessInfo() {
      this.$store.dispatch("business/fetchBusinessInfo").then((resp) => {
        const location = resp.location;
        if (location === "col") {
          this.$i18n.locale = "es";
        } else if (location === "us") {
          this.$i18n.locale = "en";
        }
        this.loading = false;
      });
    },

    fetchBranchInfo() {
      this.$store.dispatch("business/fetchBranchInfo");
      this.$store.dispatch("business/fetchBusinessInfo");
    },
    fetchBranchConfig() {
      this.$store.dispatch("business/fetchBranchConfig").then((resp) => {
        if (resp.status == 200) {
          // const styles = resp.data || resp.detail || {};
          // setCssVar("bg-color", styles?.detail?.color_background); //esto es el background
          // setCssVar("disable-btn", styles?.detail?.disable_buttons); // esto para btn desactivados
          // setCssVar("body-color", styles?.detail?.body_color); // esto para los subtitulos
          // setCssVar("body-font", styles?.detail?.body_type);
          // setCssVar("title-font", styles?.detail?.title_type);
          // setCssVar("bg-nav", styles?.detail?.background_navigation);
          // setCssVar("bg-contrast", styles?.detail?.background_contrast);
          this.careersLink = this.getBranchConfig[0]?.careers;
        }
      });
    },
  },
  fetchBranchDetails() {
    this.$store.dispatch("business/fetchBranchDetails");
    // .then((resp) => {
    //   if (resp.status == 200) {
    //     // const styles = resp.data || resp.detail || {};
    //     // setCssVar("bg-color", styles?.detail?.color_background); //esto es el background
    //     // setCssVar("disable-btn", styles?.detail?.disable_buttons); // esto para btn desactivados
    //     // setCssVar("body-color", styles?.detail?.body_color); // esto para los subtitulos
    //     // setCssVar("body-font", styles?.detail?.body_type);
    //     // setCssVar("title-font", styles?.detail?.title_type);
    //     // setCssVar("bg-nav", styles?.detail?.background_navigation);
    //     // setCssVar("bg-contrast", styles?.detail?.background_contrast);
    //     console.log("todo good");
    //   }
    // });
  },

  computed: {
    ...mapGetters("business", [
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getStyles",
      "getBranchConfigReserve",
      "getBranchConfigCareers",
    ]),
    ...mapGetters("menu", ["getMenuTime"]),
  },
  created() {
    // const business = process.env.BUSINESS;
    // const id = process.env.BRANCH;
    // this.$store.commit("business/setBusinessName", business);
    // this.$store.commit("business/setIdBranch", id);
    // this.fetchBranchInfo();
    this.fetchBranchConfig();
    // this.fetchBranchDetails();
  },
  mounted() {
    // this.fetchBusinessInfo();
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.custom-btn-footer {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  background-color: $accent;
  font-size: 1rem;
  border-radius: 0;
  width: 100%;
  height: 100%;
  border: 0;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    color: $primary;
    background-color: $accent;
  }
  &:focus {
    border: 2px solid $accent;
    background-color: black;
    color: $accent;
  }
}
.a-tel {
  color: $accent;
}
.p-div {
  padding-left: 0;

  text-decoration: underline;
  background-color: transparent;
  color: $accent;
  border: none;
  text-align: left;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
  &:active {
    background-color: transparent;
    color: $accent;
  }
  &:focus {
    border: 2px solid $accent;
    text-decoration: underline;
  }
}
.div-columns {
  display: flex;
  flex-direction: column;
  width: 105px;
  .p {
    margin-bottom: 0 !important;
  }
}
.info-column {
  max-width: 273px;
  width: 100%;
}
.text-payfud {
  margin-top: 3px;
  height: 50px;
  font-size: 1rem;
  border-top: 1px solid $accent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-reserve {
  font-family: Manrope;
  font-size: 0.875rem;
  background-color: $accent;
  color: $primary;
  text-align: center;
  width: 130px;
  height: 39px;
  margin-bottom: 39px;
  margin-top: 49px;
  &:hover {
    opacity: 0.8;
  }
}
.card-info {
  font-family: Manrope;
  font-size: 1rem;
  .info-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* El primer div ocupa 3 columnas, el segundo div ocupa 1 columna */
    gap: 241px;
    padding-left: 140px;
    padding-right: 140px;
    padding-bottom: 100px;
    text-align: left;
    margin: auto;
    max-width: 1300px;
  }
  .h6-restaurant {
    font-size: 1rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 52px;
    padding-bottom: 12px;
    font-weight: 600;
  }
  .info-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 500px;
    width: 100%;
    gap: 18px;

    .h6 {
      font-size: 1rem;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 23px;
      padding-top: 52px;
      font-weight: 600;
    }
  }
  background-color: $primary !important;
  color: $accent;
}
.info-btns {
  display: flex;
  justify-content: space-between;
  height: 188px;
  padding-left: 140px;
  padding-right: 140px;
  padding-top: 100px;
  align-items: center;
  margin: auto;
  max-width: 1300px;
}
@media screen and (max-width: 1024px) {
  .card-info .info-container {
    padding-left: 80px;
    padding-right: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
  }
  .info-btns {
    padding-left: 80px;
    padding-right: 80px;
  }
  .info-columns {
    max-width: 500px;
    display: grid;
  }
  .card-info .info-columns {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}
@media screen and (max-width: 796px) {
  .p-footer {
    padding-top: 27px;
    margin-bottom: 27px;
    padding-bottom: 80px;
  }

  .text-payfud {
    height: 120px;
  }
  .card-info .info-columns .h6 {
    padding-top: 8px;
  }
  .info-btns {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 0 !important;
  }
  .btn-reserve {
    display: none;
  }
  .card-info .info-container {
    padding-left: 25px;
    padding-right: 2px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1px;
    padding-bottom: 20px;
  }
  .card-info .info-columns {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 400px;
    width: 100%;
  }
  .card-info .h6-restaurant {
    padding-bottom: 14px;
    padding-top: 0 !important;
  }
  .card-info .info-columns .h6 {
    padding-bottom: 14px;
  }
  .p-restaurant {
    padding-bottom: 1px;
  }
}
</style>
