<template>
  <q-dialog
    maximized
    ref="cancelDialog"
    seamless
    style="background-color: #00000056"
    :transition-show="isMobile ? 'slide-up' : 'slide-right'"
  >
    <q-card class="ms-q-card ms-modal-subscribers">
      <div>
        <q-img src="@/assets/images/imgmodal.webp" alt="" style="height: 192px; width: 100%"></q-img>
        <q-btn
          dense
          flat
          round
          icon="close"
          @click="cancelModal"
          class="close-btn"
          style="position: absolute; top: 10px; right: 10px; color: white"
        />
      </div>
      <div class="auth-form">
        <h3 style="margin-top: 24px; margin-bottom: 0 !important" class="text-white title-font">Join VIP Club</h3>
        <p class="text-white body-font" style="text-align: left; margin-bottom: 15px">
          Enjoy our members only discounts and latest events at {{ getBusinessInfo.name }}.
        </p>

        <div class="inputs-names">
          <div class="first-input col-6">
            <label class="q-mb-sm body-color body-font" style="font-size: 1rem">First name</label>

            <div class="form-input" style="background-color: #071b2c">
              <q-input
                class="text-white"
                :input-style="{ height: '45px', paddingLeft: '8px' }"
                style="color: white"
                input-class=" body-color fs-16 text-white"
                borderless
                v-model="first_name"
              />
            </div>
          </div>
          <div class="last-input col-6">
            <label class="q-mb-sm body-color body-font" style="font-size: 1rem"> Last name </label>

            <div class="form-input" style="">
              <q-input
                :input-style="{ height: '45px', paddingLeft: '8px' }"
                input-class="padding: 0 16px  text-white fs-16"
                borderless
                v-model="last_name"
              />
            </div>
          </div>
        </div>

        <div class="inputs-info">
          <label class="body-color body-font" style="font-size: 1rem"> Email Address </label>
          <div class="form-input">
            <q-input
              :rules="[
                ($v) => !!$v || 'Email is required',
                ($v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test($v) || 'Invalid email format',
              ]"
              input-class="  body-color fs-16 text-white"
              borderless
              v-model="email"
              style="width: 100%"
              :input-style="{ height: '45px', paddingLeft: '0', paddingLeft: '8px' }"
            />
          </div>
          <label class="body-color body-font" style="font-size: 1rem"> Phone number </label>
          <div class="form-input">
            <div class="phone-prefix">+1</div>
            <q-input
              mask="###-###-####"
              input-class="  body-color fs-16 text-white"
              borderless
              v-model="phone"
              style="width: 100%"
              :input-style="{ height: '45px', paddingLeft: '0', paddingLeft: '8px' }"
            />
          </div>
          <label class="body-color body-font" style="font-size: 1rem"> Date of birth </label>

          <div class="form-input" style="margin-bottom: 8px !important">
            <q-input
              style="color: white !important"
              class="text-white"
              label-color="white"
              text-color="white"
              input-class="body-color fs-16 text-white"
              borderless
              v-model="birthday"
              mask="##/##/####"
              placeholder="MM/DD/YYYY"
              :input-style="{ height: '45px', padding: '0', paddingLeft: '8px' }"
            />
          </div>
        </div>
        <label class="custom-checkbox">
          <input type="checkbox" v-model="subscribe" />
          <span class="checkmark"></span>
          <p class="body-font">Get emails from {{ getBusinessInfo.name }} about offers, latest news, and events.</p>
        </label>
        <q-btn
          style="
            background-color: white;
            color: #0f426b;
            height: 51px;
            width: 346px;
            font-size: 1rem !important;
            margin-bottom: 24px;
          "
          class="full-width text-black"
          unelevated
          no-caps
          @click="sendSubscribe()"
          :disable="disableBtnSuscribe"
          label="Join now"
        />
      </div>
    </q-card>
    <q-card v-if="this.subscritionSent" class="ms-q-card ms-modal-subscribers" style="justify-content: center; display: flex">
      <div>
        <q-img src="@/assets/images/imgmodal.webp" alt="" style="height: 192px; width: 100%"></q-img>
        <q-btn
          dense
          flat
          round
          icon="close"
          @click="cancelModal"
          class="close-btn"
          style="position: absolute; top: 10px; right: 10px; color: white"
        />
      </div>
      <div class="container-successfully">
        <h2 class="h2-subscribers">You successfully joined the club</h2>
        <p class="p-subscribers">
          Thank you for joining our VIP Club. Soon you will start receiving discounts and the latest events.
        </p>
        <router-link class="btn-successfully" unelevated no-caps :to="{ name: 'events' }" label="Explore Events" />
        <router-link class="btn-close-successfully" unelevated no-caps @click="cancelModal" label="Close" />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
// import { mapGetters } from "vuex";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isMobile: false,
      subscritionSent: false,
      phone: "",
      first_name: "",
      last_name: "",
      email: "",
      subscribe: true,
      birthday: "",
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  computed: {
    ...mapGetters("business", ["getBranchInfo", "getBranchConfig", "getBusinessInfo", "getBranchDetails"]),
    disableBtnSuscribe() {
      return this.first_name.trim() === "" || this.email.trim() === "" || this.birthday.trim() === "";
    },
  },
  methods: {
    updateIsMobile() {
      // Actualiza isMobile basado en el ancho de la pantalla
      this.isMobile = window.innerWidth <= 768; // Puedes ajustar el umbral según tus necesidades
    },

    cancelModal() {
      this.$refs.cancelDialog.hide();
    },

    sendSubscribe() {
      const payload = {
        phone_number: `+1${this.phone}`,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        subscribe: this.subscribe,
        source: "web",
        birthday: this.birthday,
      };

      this.$store.dispatch("clients/subscribeClient", payload);
      this.subscritionSent = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-slide-animation .q-dialog {
  &.transition-show-enter-active {
    transition: transform 0.5s ease-in-out;
  }

  &.transition-show-enter {
    transform: translateX(100%);
  }

  &.transition-show-enter-to {
    transform: translateX(0);
  }
}

.btn-close-successfully {
  font-size: 1rem !important;
  color: $accent;
  font-weight: 0 !important;
}

.btn-successfully {
  background-color: #edf6fc;
  color: #082136;
  height: 48px;
  width: 248px;
  font-size: 1rem !important;
  margin-bottom: 24px;
  border-radius: 0 !important;
  font-weight: 0 !important;
}
.container-successfully {
  padding: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.h2-subscribers {
  width: 218px;
  font-family: Prata;
  font-size: 1.375rem;
  margin-bottom: 0;
  color: $accent;
  text-align: center;

  padding-bottom: 22px;
  margin-top: 0;
}
.p-subscribers {
  font-family: Manrope;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 24px;
  color: $accent;
  width: 263px;
  text-align: center;
  padding-bottom: 28px;
}

.inputs-info {
  display: grid;
  grid-template-columns: 1fr;
}
.q-btn:disabled {
  opacity: 0.6 !important;
}
.q-field__control-container {
  height: 45px !important;
}
.q-input .q-field__control {
  height: 45px !important;
}
.q-field__native q-placeholder {
  color: white;
  .q-field__native {
    padding-left: 0 !important;
  }
}
.text-white {
  color: white !important;
}
.cancel-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px;
}
.inputs-names {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 12px;
}

/////////////////////////////////////

.names-input {
  background-color: #071b2c;
  border: 1px solid white;
  font-size: 1rem;
  padding-left: 4px;
  color: white;
}
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  user-select: none;
  color: white;
}

.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent; /* Fondo transparente */
  border: 1px solid white; /* Borde sólido blanco */
  border-radius: 4px; /* Bordes redondeados */
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.2); /* Ligeramente visible para interacción */
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: transparent; /* Puedes ajustar este color según tus necesidades */
  margin-top: 3px;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Muestra la marca de verificación cuando se selecciona el checkbox */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Estilo de la marca de verificación */
.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.q-card {
  position: fixed !important;
}
.q-dialog__inner--maximized > div {
  left: auto !important; /* Anula la posición izquierda */
  right: 0 !important; /* Alinea el modal a la derecha */
  max-width: 376px !important;
}
.ms-modal-subscribers {
  position: fixed;
  top: 0;
  right: 0 !important;
  background-color: #082136 !important;
  height: 100dvh;
}

.ms-q-card {
  width: 100%;
}

.mp-product-info {
  padding: 0 16px 11px 16px;
}

.mp-counter {
  display: flex;
  align-items: center;

  .ms-q-card {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
  }
}

.q-checkbox__bg {
  color: transparent !important;
  background: transparent !important;
}
.q-checkbox__inner {
  border: 1px solid white !important;
  border-radius: 0 !important;
}
.p-modal-checkbox {
  font-family: Manrope;
  font-size: 1rem;
  color: white;
}
.q-tab-panel {
  padding: 0 !important;
  height: 500px;
  width: 100%;
  max-width: 426px !important;
}
.phone-prefix {
  color: white; /* Ajusta esto según tus preferencias */
  font-weight: bold; /* Ejemplo de ajuste de peso de fuente */
  margin-right: 8px; /* Espacio entre el prefijo y el input */
  margin-top: 11px;
  margin-left: 11px;
  /* Otros estilos para hacer que el prefijo parezca integrado */
}

.form-input {
  background-color: #071b2c;
  height: 45px;
  margin-top: 8px;
  margin-bottom: 12px;
  display: flex;
  border: 1px solid white;
  .q-field__control {
    height: 45px !important;
  }
}

.btn-go-back {
  margin-bottom: 15px;
}
.title-font {
  font-family: Prata;
  font-size: 1.5rem;
}

.body-font {
  font-family: Manrope !important;
  font-size: 1rem;
  color: white;
}

.auth-form {
  text-align: start;
  height: 100%;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #082136;
  height: 525px;
}

.country {
  border-right: 1px solid var(--gray);
}
.payfud-rounded {
  border-radius: 10px;
}
.list-benefits {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.text-icon {
  margin-left: 18px;
}

@media screen and (max-width: 500px) {
  .q-dialog__inner--maximized > div {
    max-width: 100% !important;
  }
}
</style>
