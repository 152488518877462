<template>
  <q-dialog @show="setProduct" @hide="reset" maximized seamless style="background-color: #00000056">
    <q-card
      v-if="product"
      ref="scrollProductModal"
      class="mp-q-card mp-modal-product"
      :scroll-target="$refs.scrollProductModal"
      @scroll="scrollHandler"
    >
      <div class="mp-product-details text-secondary" :class="position > 215 ? 'row' : 'hidden'">
        <h4 class="col-9" style="font-family: Prata; color: white; font-size: 1.125rem">
          {{ product.name }}
        </h4>
        <h4 class="col text-right body-font fs-14" style="font-family: Prata; color: white; font-size: 1.125rem">
          ${{ new Intl.NumberFormat("es-CO").format(product.total_price) }}
        </h4>
      </div>
      <div>
        <q-toolbar class="mp-fixed-head" style="background-color: #082136">
          <q-btn
            flat
            text-color="white"
            v-close-popup
            no-caps
            class="fs-14 body-font q-pl-none"
            style="margin-right: 6px; font-family: Manrope; font-size: 0.875rem"
          >
            <q-icon name="arrow_back" class="icon-with-margin" />
            Back</q-btn
          >
        </q-toolbar>
      </div>

      <q-responsive v-if="product.image" :ratio="4 / 3" class="col">
        <q-img class="product-img" :src="product.image" alt=""/>
      </q-responsive>
      <div v-else style="height: 40px"></div>

      <div class="mp-product-info">
        <div class="row div-info-product">
          <h4 class="col-9 h4-mp" style="">
            {{ product.name }}
          </h4>

          <h4 class="col text-right fs-14" style="font-family: Prata; color: white; font-size: 1.125rem">
            ${{ new Intl.NumberFormat("es-CO").format(product.total_price) }}
          </h4>
        </div>
        <p class="q-mb-none text-white fs-16" style="line-height: 24px; text-align: left; font-family: Manrope; font-size: 1rem">
          {{ product.description }}
        </p>
      </div>

      <q-card-section class="" borderless>
        <div v-if="options.length > 0" class="options">
          <div class="q-mb-md" v-for="option in product.options" :key="option.id">
            <q-expansion-item
              class="expasion-option-modal"
              header-class="q-pa-sm bg-contrast-options body-font"
              :header-style="{
                backgroundColor: '#EDF6FC',
              }"
              expand-separator
              :default-opened="option.required"
            >
              <template v-slot:header>
                <q-item-section class="mp-q-item-section">
                  <div class="col items-center">
                    <h5
                      class="col"
                      style="
                        font-family: Prata;
                        color: #082136;
                        text-align: left;
                        font-size: 1rem;
                        margin-top: 0 !important;
                        margin-bottom: 0 !important;
                      "
                    >
                      {{ option.name }}
                    </h5>
                    <p class="q-ma-none fs-16" style="font-family: Manrope; color: #082136; text-align: left">
                      Choose {{ option.max_required }} option(s)
                    </p>
                  </div>
                </q-item-section>

                <q-item-section class="mp-q-item-section body-font" side>
                  <q-chip
                    v-if="option.required"
                    class="q-mx-none body-font fs-14"
                    color="bg-color "
                    text-color="white"
                    no
                    caps
                    label="Required"
                    style="font-family: Manrope; background-color: #082136"
                  ></q-chip>
                </q-item-section>
              </template>
              <div class="body-font fs-16" v-for="(elect, index2) in option.election" :key="elect.id">
                <div class="mp-container-options body-font text-white">
                  <span class="option-name body-font"> {{ elect.name }}</span>
                  <!-- <span class="option-price body-font"> +${{ new Intl.NumberFormat("es-CO").format(elect.price) }}</span> -->
                  <!-- <span class="option-price body-font"> +${{ new Intl.NumberFormat("es-CO").format(elect.price) }}</span> -->
                </div>
                <q-separator style="background-color: #303030" v-if="index2 < option.election.length - 1" />
                <q-separator style="background-color: #303030" v-if="index2 < option.election.length - 1" />
              </div>
            </q-expansion-item>
          </div>
        </div>
        <!-- <div v-if="additions.length > 0" class="options">
          <div
            v-for="(addition, index) in product.additions"
            :key="addition.id"
          >
            <q-expansion-item
              header-class="q-pa-sm bg-grey-2"
              header-style="border-radius: 10px"
              expand-separator
              :default-opened="addition.required"
            >
              <template v-slot:header>
                <q-item-section>
                  <div class="col items-center">
                    <h5 class="col">{{ addition.name }}</h5>
                    <p class="q-ma-none">
                      Elige {{ addition.max_required }} opción(es)
                    </p>
                  </div>
                </q-item-section>

                <q-item-section side>
                  <q-chip
                    v-if="addition.required"
                    class="q-mx-none"
                    color="primary"
                    text-color="white"
                    label="REQUERIDO"
                  ></q-chip>
                </q-item-section>
              </template>
              <div v-for="(elect, index2) in addition.election" :key="elect.id">
                <div v-if="addition.selection_times == 1">
                  <CheckboxGroup
                    :indexOf="index"
                    :election="elect"
                    :options="additions"
                    :optionOrAddition="2"
                    :setElection="setElectionOfOption"
                    :initialVal="
                      additions[index].elections.find(
                        (element) => element.id == elect.id
                      )
                    "
                  />
                </div>
                <div v-else>
                  <ElectionQuantityHandler
                    :election="elect"
                    :additions="additions"
                    :index="index"
                    :setMore="setMoreElectionOfOption"
                    :setLess="setLessElectionOfOption"
                    :optionOrAddition="2"
                  />
                  <span v-if="elect.price > 0"
                    >+ ${{
                      new Intl.NumberFormat("es-CO").format(elect.price)
                    }}</span
                  >
                </div>
                <q-separator v-if="index2 < option.election.length - 1" />
              </div>
            </q-expansion-item>
          </div>
        </div> -->
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  props: {
    currentProduct: {
      type: Number,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },

    currentOptionsAndAdditions: {
      type: Object,
      required: true,
    },
    reRender: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      additions: [],
      options: [],
      product: null,
      orderType: 3,

      quantity: 1,
      position: 0,
    };
  },
  computed: {
    disableBtn() {
      return this.quantity == 0 || this.selectedRequired;
    },
    selectedRequired() {
      let required = false;
      for (let index = 0; index < this.additions.length; index++) {
        const addition = this.additions[index];
        if (addition.required) {
          if (addition.elections.length < 1) {
            required = true;
          }
        }
      }
      for (let index = 0; index < this.options.length; index++) {
        const option = this.options[index];
        if (option.required) {
          if (option.elections.length < 1) {
            required = true;
          }
        }
      }
      return required;
    },
    getTotalElections() {
      let totalPrice = 0;
      for (let index = 0; index < this.options.length; index++) {
        const option = this.options[index];
        for (let index = 0; index < option.elections.length; index++) {
          const election = option.elections[index];
          if (election.price > 0) {
            totalPrice = totalPrice + election.price * election.quantity;
          }
        }
      }
      for (let index = 0; index < this.additions.length; index++) {
        const addition = this.additions[index];
        for (let index = 0; index < addition.elections.length; index++) {
          const election = addition.elections[index];
          if (election.price > 0) {
            totalPrice = totalPrice + election.price * election.quantity;
          }
        }
      }
      return totalPrice;
    },
    getTotalToPay() {
      return this.quantity > 0
        ? (this.getTotalElections + this.product.total_price) * this.quantity
        : this.getTotalElections + this.product.total_price;
    },
  },
  methods: {
    scrollHandler(w) {
      this.position = w.srcElement.scrollTop;
    },
    setProduct() {
      this.product = this.currentProduct;
      if (this.currentOptionsAndAdditions) {
        this.options = this.currentOptionsAndAdditions.options;
        this.additions = this.currentOptionsAndAdditions.additions;
      } else {
        this.setOptionsAndAdditions();
      }
    },
    setOptionsAndAdditions() {
      for (let index = 0; index < this.product.options.length; index++) {
        const option = this.product.options[index];
        this.options.push({
          name: option.name,
          id: index,
          elections: [],
          max: option.max_required,
          maxPerItems: option.selection_times,
          required: option.required,
          quantity: 1,
        });
      }
      for (let index = 0; index < this.product.additions.length; index++) {
        const addition = this.product.additions[index];
        this.additions.push({
          name: addition.name,
          id: index,
          elections: [],
          max: addition.max_required,
          maxPerItems: addition.selection_times,
          required: addition.required,
          quantity: 1,
        });
      }
    },
    setElectionOfOption({ election, index }) {
      const existsElection = this.options[index].elections.some((elec) => elec.id == election.id);
      if (existsElection) {
        for (let i = 0; i < this.options[index].elections.length; i++) {
          const element = this.options[index].elections[i];
          if (element.id === election.id) {
            this.options[index].elections.splice(i, 1);
          }
        }
      } else {
        if (this.options[index].elections.length < this.options[index].max) {
          election.quantity = 1;
          this.options[index].elections.push(election);
        }
      }
    },
    setElectionOfAddition({ election, index }) {
      if (this.additions[index].elections.includes(election)) {
        for (let i = 0; i < this.additions[index].elections.length; i++) {
          const element = this.additions[index].elections[i];
          if (element.id === election.id) {
            this.additions[index].elections.splice(i, 1);
          }
        }
      } else {
        if (this.additions[index].elections.length < this.additions[index].max) {
          election.quantity = 1;
          this.additions[index].elections.push(election);
        }
      }
    },
    setMoreElectionOfOption({ election, index }) {
      const elections = this.options[index].elections;
      const currentElection = elections.find((elec) => elec.id == election.id);
      if (elections.includes(currentElection)) {
        const idx = this.options[index].elections.indexOf(currentElection);
        this.options[index].elections[idx].quantity++;
      } else {
        election.quantity = 1;
        this.options[index].elections.push(election);
      }
    },
    setLessElectionOfOption({ election, index }) {
      const elections = this.options[index].elections;
      const currentElection = elections.find((elec) => elec.id == election.id);

      // Compruebo que si existe la eleccion
      if (elections.includes(currentElection)) {
        const idx = elections.indexOf(currentElection);

        if (elections[idx].quantity == 1) {
          // Si la cantidad llega a cero, se elimina de la lista
          this.options[index].elections.splice(idx, 1);
        } else {
          // Solo se restara la cantidad
          elections[idx].quantity--;
        }
      }
    },
    setMoreElectionOfAddition({ election, index }) {
      const elections = this.additions[index].elections;
      const currentElection = elections.find((elec) => elec.id == election.id);
      if (elections.includes(currentElection)) {
        const idx = this.additions[index].elections.indexOf(currentElection);
        this.additions[index].elections[idx].quantity++;
      } else {
        election.quantity = 1;
        this.additions[index].elections.push(election);
      }
    },
    setLessElectionOfAddition({ election, index }) {
      const elections = this.additions[index].elections;
      const currentElection = elections.find((elec) => elec.id == election.id);

      // Compruebo que si existe la eleccion
      if (elections.includes(currentElection)) {
        const idx = elections.indexOf(currentElection);

        if (elections[idx].quantity == 1) {
          // Si la cantidad llega a cero, se elimina de la lista
          this.additions[index].elections.splice(idx, 1);
        } else {
          // Solo se restara la cantidad
          elections[idx].quantity--;
        }
      }
    },
    moreQuantity() {
      this.quantity++;
    },
    lessQuantity() {
      if (this.quantity > 0) {
        this.quantity--;
      }
    },
    reset() {
      this.product = null;
      this.additions = [];
      this.options = [];
      this.quantity = 1;
      this.$store.commit("currentProduct/resetProduct");
    },
    getInfo() {
      return {
        product: this.product,
        order_type: this.orderType,
        quantity: this.quantity,
        additions: this.additions,
        options: this.options,
      };
    },
    addProduct() {
      this.$store.commit("currentOrder/addProduct", this.getInfo());
    },
    updateProduct() {
      this.$store.commit("currentOrder/updateProduct", this.getInfo());
      this.reRender();
    },
    deleteProduct() {
      this.$store.commit("currentOrder/removeProduct", this.product.id);
      this.reRender();
    },
  },
};
</script>

<style lang="scss" scoped>
.q-dialog__inner--maximized > div {
  left: auto !important; /* Anula la posición izquierda */
  right: 0 !important; /* Alinea el modal a la derecha */
  max-width: 426px !important;
}
.q-item__section--main ~ .q-item__section--side {
  color: #082136;
}

.q-item__section--main ~ .q-item__section--side {
  color: #082136;
}
.q-dialog__inner--maximized > div {
  left: auto !important;
}
.div-info-product {
  height: 65px;
}
.h4-mp {
  font-family: Prata;
  color: white;
  font-size: 1.125rem;
  margin-bottom: 5px !important;
  height: 39px;
}
.q-expansion-item__arrow-icon {
  color: #082136 !important;
}
.icon-with-margin {
  margin-right: 6px;
}
.body-font {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}
.bg-contrast-options {
  background: white !important;
  background: white !important;
}
.mp-container-options {
  padding: 10px;
  font-size: initial;
  display: flex;
  justify-content: space-between;
}
.product-price {
  margin-left: 20px; /* Añade un margen entre el nombre y el precio */
}
.mp-modal-product {
  position: fixed;
  top: 0;
  right: 0 !important;
  background-color: #082136 !important;
}

.mp-product-details {
  position: fixed;
  top: 0;
  padding: 16px 24px 16px 68px;
  background-color: white;
  z-index: 1;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
}

.mp-q-img {
  height: 162px;
}

.mp-q-card {
  width: 100%;
}

.mp-product-info {
  padding: 0 16px 11px 16px;
}

.mp-product-config {
  padding: 0 20px;
}

.mp-counter {
  display: flex;
  align-items: center;

  .mp-q-card {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
  }

  .q-btn {
    height: 30px !important;
    width: 30px !important;
  }
}

.mp-fixed-head {
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  z-index: 99;
}
</style>
