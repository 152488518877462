<template>
  <div class="card-atmos">
    <div class="div-atmos">
      <div>
        <h6 class="h6-atmos">Atmosphere</h6>
      </div>
      <p class="p-atmos">We got inspired on ..., we wanted to make our guests feel ....</p>
      <div class="q-pa-md">
        <div class="div-imgs">
          <div class="col-4">
            <q-img src="https://picsum.photos/500/300" :ratio="16 / 9" alt="" />
          </div>

          <div class="col-4">
            <q-img src="https://picsum.photos/500/300" :ratio="1" alt="" />
          </div>

          <div class="col-4">
            <q-img src="https://picsum.photos/500/300" :ratio="4 / 3" alt="" />
          </div>
          <div class="col-4">
            <q-img src="https://picsum.photos/500/300" :ratio="16 / 9" alt="" />
          </div>

          <div class="col-4">
            <q-img src="https://picsum.photos/500/300" :ratio="1" alt="" />
          </div>

          <div class="col-4">
            <q-img src="https://picsum.photos/500/300" :ratio="4 / 3" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Best Atmosphere Restaurant in Tampa",
      description: "Come relax with friends in our cozy atmosphere. Enjoy live entertainment, tasty drinks, and delicious food",
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTitle();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle();
    next();
  },
  methods: {
    setTitle() {
      document.title = this.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", this.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", this.description);
        document.head.appendChild(newMeta);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.div-atmos {
  max-width: 1300px;
  margin: auto;
}
.col-4 {
  height: fit-content;
  break-inside: avoid;
  margin-bottom: 8px;
}
.p-atmos {
  font-size: 1rem;
  font-family: Manrope;
  color: #434343;
  text-align: left;
}
.card-atmos {
  background-color: $background;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 25px;
}
.h6-atmos {
  margin-top: 170px;
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: #1d1d1d;
  text-align: left;
}
.div-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  grid-auto-rows: auto; // Esto hace que las filas se ajusten al contenido
  align-items: start;
  // column-count: 2;
  // column-gap: 8px;
}
@media screen and (max-width: 1024px) {
  .card-atmos {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 796px) {
  .div-imgs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .card-atmos {
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 20px;
  }
}
</style>
