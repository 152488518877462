export default {
  common: {
    back: "Back",
    menu: "{restaurant} Menu",
  },
  pages: {
    logout: {
      bye: "Come back soon!",
      byeDescription: "We hope you enjoyed your experience at",
    },
    menu: {
      menus: "Menus",
      seeMenu: "See menu",
      searchProduct: "Search in menu",
      chooseOptions: "Choose { max_required } option(s)",
      chooseAditions: "Choose { max_required } addition(s)",
      required: "Required",
      noProduct: "We couldn't find a product with that name",
      noMenu: "There is no menu available for this restaurant yet",
    },
    restaurant: {
      follow: "Follow",
      review: "Review",
    },
    productlist: {
      notFound: "We couldn't find a product with that name :",
    },

    entering: {
      title: "Welcome to",
      numero: "Please enter your phone number",
      politic: "By continuing I agree with the",
      suscription: "Subscribe to { restaurant } latest news and events",
      next: "Continue",
      skip: "Skip to see menu",
    },
    help: {
      title: "Do you need help?",
      subtitle: "Select the type of help you need",
      request: "Request check-out",
      waiter: "Request a server",
    },
    mainlayout: {
      help: "Help",
      menu: "Menu",
      orderOnline: "Order online",
      reserve: "Reserve now",
    },
    days: {
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
    },
  },
};
