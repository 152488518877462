<template>
  <section id="scroll-list" class="menu-list" :style="{ visibility: loadingMenuProducts ? 'hidden' : 'visible' }">
    <div v-if="!noOneProduct" class="container-lists">
      <p style="text-align: left; color: white; font-family: Manrope; font-size: 1rem; margin-top: 28px">
        {{ menuGroups.description }}
      </p>
      <div class="category-list" v-for="(category, index) in categories" :key="index">
        <div class="category-header">
          <h2 :style="{ ...getStyles.h2 }" :id="category.ref" class="category-title">
            {{ category.name }}
          </h2>
        </div>
        <q-item-section class="mp-q-item-section" side> </q-item-section>
        <div
          v-if="category.description.length > 0 && category.products.length != 0"
          class="q-mb-sm text-left text-white fs-16"
          style="font-weight: 400; font-family: Manrope"
        >
          <p :style="{ ...getStyles.p }" style="margin: 0">
            {{ category.description }}
          </p>
        </div>
        <div v-if="category.products.length > 0" class="list-by-category">
          <ProductItem
            v-for="item in category.products"
            :key="item.id"
            :openModal="toggleModalProduct"
            :food="item"
            :categoryAvailability="category.is_available"
          ></ProductItem>
        </div>
      </div>
      <div class="offset"></div>
    </div>
    <div v-else-if="!productsLoaded">
      <p>{{ $t("pages.productlist.notFound") }}</p>
    </div>
  </section>
  <ModalProduct v-model="productModal" :currentProduct="currentProduct" :close="toggleModalProduct" />
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import ProductItem from "../components/ProductItem.vue";
export default {
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },

    loadingMenuProducts: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ProductItem,
    ModalProduct: defineAsyncComponent(() => import("../modals/ModalProduct.vue")),
  },
  data() {
    return {
      productModal: false,
      onlyReadModal: false,
      maximizedToggle: true,
      currentProduct: null,
      productsLoaded: false,
    };
  },

  computed: {
    ...mapGetters({
      categories: "menu/getFilteredCategories",
      menuGroups: "menu/getMenuTime",
    }),
    ...mapGetters("business", ["getBranchInfo", "getStyles"]),
    noOneProduct() {
      let products = 0;
      for (let index = 0; index < this.categories.length; index++) {
        const element = this.categories[index];
        products = products + element.products.length;
      }
      return products == 0 && this.productsLoaded;
    },
  },
  methods: {
    toggleModalProduct(product) {
      const body = {
        product: product.id,
        client_id: null,
        sucursal: this.getBranchInfo[0].id,
      };
      this.$store.dispatch("menu/mostLessProducts", body);
      if (this.onlyRead) {
        this.onlyReadModal = !this.onlyReadModal;
      } else {
        this.productModal = !this.productModal;
      }
      this.currentProduct = product;
      this.$store.commit("menu/setFoodConfig", product);
    },
  },
  mounted() {
    this.productsLoaded = true;
    this.$emit("productListLoaded");
  },
};
</script>
<style lang="scss" scoped>
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  box-sizing: border-box;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 16px;
  /* padding-right: 30px; */
  background-color: var(--q-bg-products);
  /* overflow-y: auto; */
  /* padding-bottom: 50px; */
  scroll-behavior: smooth;
  height: auto;
}
.container-lists {
  /* width: 100%; */
  /* width: calc(100% - 45px); */
  height: auto;
  /* max-height: calc(100vh - 305px); */
  overflow: visible;
  margin-top: 43px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1036px;
}
.category-list {
  margin-bottom: 10px;
}
.category-title {
  height: 41px;
  text-align: start;
  margin-top: 10px;
  color: white;
  font-size: 1.25rem;
  margin-bottom: 10px !important;
}
.list-by-category {
  margin-top: 28px;
  display: grid;
  row-gap: 21px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.category-description {
  font-size: 14px;
  color: white;
}

.offset {
  height: 60px;
}

@media screen and (min-width: 995px) {
  .menu-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    box-sizing: border-box;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 16px;
    /* padding-right: 30px; */
    background-color: var(--q-bg-products);
    /* overflow-y: auto; */
    /* padding-bottom: 50px; */
    scroll-behavior: smooth;
    height: auto;
  }
  /* .list-by-category {
    display: grid;
    row-gap: 21px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  } */
}
@media screen and (max-width: 1115px) {
  .container-lists {
    margin-top: 45px;
    width: 100%;
  }
}
@media screen and (max-width: 837px) {
  .container-lists {
    margin-top: 38px;
    width: 100%;
  }
  .list-food {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
  }
  .list-by-category {
    margin-top: 28px;
    display: grid;
    row-gap: 21px;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
@media screen and (min-width: 414px) {
  .menu-list {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 60px; */
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 16px;
    height: auto;
    /* padding-right: 30px; */
    background-color: var(--q-bg-products);
    /* overflow-y: auto; */
    /* padding-bottom: 50px; */
    scroll-behavior: smooth;
  }
}
</style>
