import api from "@/api";
import axios from "axios";

const business = {
  namespaced: true,
  state: {
    businessName: null,
    idBranch: null,
    businessInfo: {},
    restaurant: {},
    branchInfo: {},
    branchConfig: {},
    branchDetails: {},
    events: [],
    eventsDetail: [],
    reviews: [],
    faqs: [],
    styles: {},
  },

  actions: {
    fetchStyles({ state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${state.businessName}/v1/web-fonts`).then(
          (response) => {
            resolve(response.data);
            // commit("setStyles", styles);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    fetchEventsDetail({ commit, state }, eventId) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${state.businessName}/v1/events/get_event?id=${eventId}`).then(
          (response) => {
            resolve(response.data);
            commit("setEventsDetail", response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchFaqs({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${state.businessName}/v1/faqs`).then(
          (response) => {
            resolve(response.data);
            commit("setFaqs", response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    fetchEvents({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${state.businessName}/v1/events`).then(
          (response) => {
            resolve(response.data);
            commit("setEvents", response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchReviews({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${state.businessName}/v1/restaurant-reviews`).then(
          (response) => {
            resolve(response.data);
            commit("setReviews", response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchBusinessInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/v1/public-business?name=${state.businessName}`).then(
          (response) => {
            resolve(response.data);
            commit("setBusinessInfo", response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    async fetchBranchInfo({ commit, state }) {
      const response = await axios.get(`${api}/r/${state.businessName}/v1/public-branches`);
      commit("setBranchInfo", response.data);
      return response;
    },
    async fetchBranchConfig({ commit, state }) {
      const response = await axios.get(`${api}/r/${state.businessName}/v1/configs`);
      commit("setBranchConfig", response.data);
      return response;
    },
    async fetchBranchDetails({ commit, state }) {
      const response = await axios.get(`${api}/r/${state.businessName}/v1/public-details/1`);
      commit("setBranchDetails", response.data);
      return response;
    },
  },
  mutations: {
    setStyles(state, payload) {
      state.styles = payload;
    },
    setEventsDetail(state, payload) {
      state.eventsDetail = payload;
    },

    setFaqs(state, payload) {
      state.faqs = payload;
    },

    setEvents(state, payload) {
      state.events = payload;
    },
    setReviews(state, payload) {
      state.reviews = payload;
    },
    setBusinessName(state, payload) {
      state.businessName = payload;
    },
    setIdBranch(state, payload) {
      state.idBranch = payload;
    },
    setBusinessInfo(state, payload) {
      state.businessInfo = payload;
    },
    setRestaurant(state, data) {
      state.restaurant = data;
    },
    setBranchInfo(state, payload) {
      state.branchInfo = payload;
    },
    setBranchConfig(state, payload) {
      state.branchConfig = payload;
    },
    setBranchDetails(state, payload) {
      state.branchDetails = payload;
    },
  },
  getters: {
    getStyles(state) {
      return state.styles;
    },

    getEventsDetail(state) {
      return state.eventsDetail;
    },

    getFaqs(state) {
      return state.faqs;
    },

    getEvents(state) {
      return state.events;
    },
    getReviews(state) {
      return state.events;
      // return [{ id: 1, review: "Test Review" }];
    },
    getIdBranch(state) {
      return state.idBranch;
    },
    getBusinessName(state) {
      return state.businessName;
    },
    getBusinessInfo(state) {
      return state.businessInfo;
    },
    getRestaurant(state) {
      return state.restaurant;
    },

    getBranchInfo(state) {
      return state.branchInfo;
    },
    getBranchConfig(state) {
      return state.branchConfig;
    },
    getBranchConfigReserve(state) {
      return state.branchConfig.reserve_link;
    },

    getBranchConfigCareers(state) {
      return state.branchConfig.careers;
    },
    getBranchDetails(state) {
      return state.branchDetails;
    },
  },
};
export default business;
