<template>
  <div class="card-atmos">
    <div class="div-amtos">
      <div class="div-titles">
        <div class="title-card">
          <h2 :style="{ ...getStyles.h2, color: getStyles.h2?.colorSecondary }" class="h4-atmos">Atmosphere</h2>
        </div>
        <div class="title-card-p">
          <button class="p-atmos" style="">
            Explore Atmosphere <q-icon class="icon-arrow" name="chevron_right" style="font-size: 24px"></q-icon>
          </button>
        </div>
      </div>

      <div class="">
        <div class="div-imgs">
          <div class="col-4">
            <q-img src="@/assets/images/atmos1.webp" alt="Bar Avenue Blu" />
          </div>

          <div class="col-4">
            <q-img src="@/assets/images/atmos2.webp" alt=" Restaurant Avenue Blu" />
          </div>
        </div>
      </div>
    </div>
    <div class="title-card-p-mb">
      <button class="p-atmos-mobile" style="">
        Explore Atmosphere <q-icon class="icon-arrow" name="chevron_right" style="font-size: 24px"></q-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },

  methods: {
    goToAtmosphere() {
      this.$router.push({ name: "atmosphere" });
    },
  },
  computed: {
    ...mapGetters("business", ["getStyles"]),
  },
};
</script>

<style lang="scss" scoped>
.title-card-p-mb {
  display: none;
}
.div-titles {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px;

  height: 50px;
}
.h4-atmos {
  color: $primary;
  font-size: 2rem;
  font-family: Prata;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.icon-arrow {
  text-decoration: none !important;
}
.title-card-p {
  display: block;
  height: 30px;
}

.div-atmos {
  max-width: 1300px;
  margin: auto;
}

.p-img {
  text-align: left;
  font-size: 2rem;
}
.p-atmos {
  height: 30px;
  color: $primary;
  text-align: left;
  font-family: Manrope;
  font-size: 1.1rem;
  margin-bottom: 0 !important;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    text-decoration: underline;
  }
  &:focus {
    background-color: transparent;
    border: 3px solid $primary;
    text-decoration: underline;
    padding-bottom: 28px;
  }
}
.card-atmos {
  // background-color: $background;

  max-width: 1068px;
  margin: auto;
  padding-bottom: 100px;
}
.h6-atmos {
  margin-top: 170px;
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: #1d1d1d;
  text-align: left;
}
.div-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media screen and (max-width: 1024px) {
  .card-atmos {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 883px) {
  .title-card-p {
    display: none;
  }
  .title-card-p-mb {
    text-align: left;
    display: block;
    display: flex;
  }
  .card-atmos {
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 34px;
    padding-top: 34px;
  }
  .div-imgs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .p-atmos-mobile {
    padding: 0;
    margin-top: 29px;
    margin-bottom: 36px;
    height: 30px;
    color: $primary;
    text-align: left;
    font-family: Manrope;
    font-size: 1.1rem;

    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: underline;
    }
    &:focus {
      background-color: transparent;
      border: 3px solid $primary;
      text-decoration: underline;
      padding-bottom: 28px;
    }
  }
}
</style>
