<template>
  <q-card flat bordered class="product-item" @click="openModal(food)">
    <div class="food-info">
      <h5
        :style="{ ...getStyles.h5, color: getStyles.small?.colorSecondary, 'padding-right': food.image ? '0' : '87px' }"
        class="title-product"
      >
        {{ food.name }}
      </h5>
      <div>
        <small :style="{ ...getStyles.small, color: getStyles.small?.colorSecondary }" style="" class="">
          {{ food.description }}
        </small>
      </div>
      <div class="food-item--footer">
        <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }">
          $ {{ new Intl.NumberFormat("es-CO").format(food.total_price) }}
        </p>
      </div>
    </div>
    <q-avatar v-if="!noImg" style="width: 142px; height: 129px" size="87px">
      <img class="food-img" :src="food.image" alt="" />
    </q-avatar>
    <!-- <q-btn
      class="add-btn"
      color="secondary"
      :style="{ color: 'var(--q-bg-color)' }"
      text-color="bg-nav"
      icon="add"
      @click="openModal(food)"
    /> -->
    <q-chip
      v-if="food.spicy === true"
      class="badge-available"
      style="
        position: absolute;
        right: 0;
        top: 14px;
        max-width: 124px;
        background-color: #9f0404;
        color: white;
        font-size: 0.75rem;
        font-family: Manrope;
        border-radius: 15px 0 0 15px;
        margin: 0;
      "
    >
      <template v-slot:default>
        <div style="display: flex; justify-content: space-between">
          <img src="@/assets/images/pepper.webp" alt="Icono Spicy" style="margin-right: 5px" />
          <span>Spicy</span>
        </div>
      </template>
    </q-chip>
  </q-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    food: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Function,
      required: true,
    },

    categoryAvailability: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("business", ["getStyles"]),
    withPrice() {
      return this.food.is_active;
    },
    formattedPrice() {
      const price = this.food.total_price;

      if (this.location === "col") {
        // Formatear el precio en pesos colombianos
        return `$ ${new Intl.NumberFormat("es-CO").format(price)}`;
      } else if (this.location === "us") {
        // Formatear el precio en dólares estadounidenses
        return `$ ${new Intl.NumberFormat("en-US").format(price)}`;
      }

      // Si la ubicación no es "col" ni "us", devolver el precio sin formato
      return `$ ${price}`;
    },
    name() {
      const name = this.food.name.substring(0, 36);
      return name;
    },
    description() {
      const description = this.food.description.substring(0, 58);
      return description;
    },
    noImg() {
      return this.food.image === null;
    },
    truncatedDescription() {
      const maxDescriptionLength = 48; // El número máximo de caracteres antes de agregar puntos suspensivos
      const description = this.food.description;

      if (description.length <= maxDescriptionLength) {
        return description; // No es necesario truncar
      } else {
        return `${description.slice(0, maxDescriptionLength - 3)}...`; // Agrega los puntos suspensivos
      }
    },
    truncatedNameProduct() {
      const maxDescriptionLength = 16; // El número máximo de caracteres antes de agregar puntos suspensivos
      const name = this.food.name;

      if (name.length <= maxDescriptionLength) {
        return name; // No es necesario truncar
      } else {
        return `${name.slice(0, maxDescriptionLength - 3)}...`; // Agrega los puntos suspensivos
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.title-product {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 16px !important;
  margin-bottom: 3px !important;
  font-size: 1rem;
}
.bagde-with-price {
  // Ajusta la posición del q-chip cuando hay un precio
  bottom: 0px !important; // Ajusta según sea necesario
}

h5 {
  font-weight: 600;
}
.product-item {
  min-height: 129px;
  width: 100%;
  height: auto;
  border-radius: 0px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;

  background-color: #f9fdff;
  text-align: start;
}
.food-img {
  height: 84px;
  min-height: 84px;
  width: 84px;
  min-width: 84px;
  background-color: gray;
  align-self: center;
}
.food-info {
  padding-right: 5px;
  height: 100%;
  min-width: 0;
  align-items: flex-start;
  color: #082136;

  small {
    font-size: 12px;
  }
}
.info {
  max-height: 72px;
}
p {
  font-weight: 700;
  margin: 0;
  text-align: start;
  line-height: 12px;
}
small {
  margin-top: 5px;
  font-size: 12px;
  text-align: start;
  height: 28px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 15px;
  font-size: 0.875rem;
  font-family: Manrope;
  text-overflow: ellipsis;
}
.add-btn {
  width: 36px;
  height: 36px !important;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;
  border-radius: 5px 0px;
}
/* @media screen and (min-width: 1023px) {
      .food-item {
        min-width: 254px;
        width: 100%;
        max-width: 370px;
      }
    }
    @media screen and (min-width: 1359px) {
      .food-item {
        height: 157px;
        padding: 16px;
      }
      .add-button {
        height: 44px;
        width: 44px;
      }
    } */
</style>
