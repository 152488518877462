<template>
  <div class="fixed-reserve" ref="myDiv">
    <a :href="getBranchConfigReserve" class="custom-tabs" style="border: none; cursor: pointer">
      <q-icon
        name="calendar_month"
        style="margin-right: 8px; color: white; margin-bottom: 2px; height: 16px; width: 16px"
      ></q-icon>
      Reserve
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    goToReservationPage() {
      const reserveLink = this.getBranchConfig?.reserve_link;

      if (reserveLink) {
        window.open(reserveLink, "_blank");
      }
    },
    fetchBusinessInfo() {
      this.$store.dispatch("business/fetchBusinessInfo").then((resp) => {
        const location = resp.location;
        if (location === "col") {
          this.$i18n.locale = "es";
        } else if (location === "us") {
          this.$i18n.locale = "en";
        }
        this.loading = false;
      });
    },

    fetchBranchInfo() {
      this.$store.dispatch("business/fetchBranchInfo");
      this.$store.dispatch("business/fetchBusinessInfo");
    },
    fetchBranchConfig() {
      this.$store.dispatch("business/fetchBranchConfig");
      // .then((resp) => {
      //   if (resp.status == 200) {
      //     // const styles = resp.data[0];
      //     // setCssVar("secondary", styles?.color);
      //     // setCssVar("card_stroke", styles?.card_stroke);
      //     // setCssVar("instagram", styles?.instagram);
      //     // setCssVar("review", styles?.review || "#DBB244");
      //     // setCssVar("bg-options", styles?.background_options);
      //     // setCssVar("bg-search", styles?.background_search);
      //     // setCssVar("bg-products-item", styles?.background_products_item);
      //     // setCssVar("bg-products", styles?.background_products);
      //     // setCssVar("bg-modal", styles?.background_modal);
      //     // setCssVar("bg-tabs", styles?.background_tabs);
      //     console.log("todo good");
      //   }
      // });
    },
    fetchBranchDetails() {
      this.$store.dispatch("business/fetchBranchDetails");
      // .then((resp) => {
      //   if (resp.status == 200) {
      //     // const styles = resp.data || resp.detail || {};
      //     // setCssVar("bg-color", styles?.detail?.color_background); //esto es el background
      //     // setCssVar("disable-btn", styles?.detail?.disable_buttons); // esto para btn desactivados
      //     // setCssVar("body-color", styles?.detail?.body_color); // esto para los subtitulos
      //     // setCssVar("body-font", styles?.detail?.body_type);
      //     // setCssVar("title-font", styles?.detail?.title_type);
      //     // setCssVar("bg-nav", styles?.detail?.background_navigation);
      //     // setCssVar("bg-contrast", styles?.detail?.background_contrast);
      //     console.log("todo good");
      //   }
      // });
    },
  },
  computed: {
    ...mapGetters("business", [
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getBranchConfigReserve",
    ]),
  },
  // created() {
  //   this.fetchBranchInfo();
  //   this.fetchBranchConfig();
  //   this.fetchBranchDetails();
  // },
  // mounted() {
  //   this.fetchBusinessInfo();
  // },
};
</script>

<style lang="scss" scoped>
.fixed-reserve {
  height: 100%;
}
.custom-tabs {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
  font-family: Manrope;
  height: 100%;
  align-items: center;
  background-color: #0a3669;
}
</style>
