<template>
  <div class="card-home">
    <div class="home-online">
      <video
        autoplay
        muted
        playsinline
        loop
        poster="@/assets/images/backghome.webp"
        class="background-video"
        alt="Best atmosphere restaurant in Tampa"
      >
        <source src="https://payfud-local-storage.s3.amazonaws.com/branches/videos/Hero+bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="row items-start">
        <div class="my-card">
          <div>
            <h1 :style="{ ...getStyles.h1 }" class="h4-home">Avenue Blu: Live Entertainment Dining</h1>
          </div>
          <div class="btns-home">
            <a no-caps flat class="btn-reserve" target="_blank" :href="getBranchConfigReserve">Reserve</a>
            <router-link no-caps outline class="btn-explore" :to="{ name: 'menu' }">Explore menu</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="div-recommend">
      <div id="circle_v3" tabindex="0" style="width: calc(294px * 0.75); height: calc(227px * 0.75)">
        <div class="rb_flex rb_top">
          <a target="_blank" href="https://www.restaurantji.com/fl/tampa/avenue-blu/" style="color: #000">
            <div
              class="circletype_v3_brand_name"
              id="circletype_v3_brand_name"
              style="top: calc(50% - 12px * 0.75); font-size: calc(18px * 0.75)"
            >
              Avenue Blu
            </div>
          </a>
          <div class="arc-heading arc-heading__bottom">
            <a href="https://www.restaurantji.com/fl/tampa/" target="_blank">Restaurantji</a>
          </div>
        </div>
      </div>
    </div>
    <div flat class="stars-card">
      <div>
        <div class="card-section" data-aos="fade-up">
          <div class="star-a" style="">
            <q-icon name="restaurant_menu" size="48px"></q-icon>
            <span :style="{ ...getStyles.span, color: getStyles.span?.colorSecondary }" class="span-stars-card"
              >American Caribbean Cuisine
            </span>
          </div>
          <div class="star-b">
            <q-icon name="nightlife" size="48px"></q-icon>
            <span :style="{ ...getStyles.span, color: getStyles.span?.colorSecondary }" class="span-stars-card"
              >Live DJ Friday to Sunday</span
            >
          </div>
          <div class="star-c">
            <q-icon name="cake" size="48px"></q-icon>
            <span :style="{ ...getStyles.span, color: getStyles.span?.colorSecondary }" class="span-stars-card"
              >Best Celebration Dinner</span
            >
          </div>
        </div>
      </div>
    </div>
    <div flat class="cousine-card">
      <SelectedRecommended :bestSellerProducts="bestSellerProducts" data-aos="fade-up" :loadedProducts="loadedProducts" />
    </div>
    <div flat class="atmosphere-card">
      <AtmosphereHome data-aos="fade-up" />
    </div>
    <div flat class="reviews-card">
      <ReviewsHome data-aos="fade-up" :allReviews="allReviews" />
    </div>
    <div flat class="card-enjoy">
      <video
        autoplay
        muted
        playsinline
        loop
        poster="@/assets/images/enjoybg.webp"
        class="background-video"
        alt="Best atmosphere restaurant in Tampa"
      >
        <source src="https://payfud-local-storage.s3.amazonaws.com/branches/videos/DJ+CTA.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <q-card-section data-aos="fade-up" style="z-index: 2">
        <h3 :style="{ ...getStyles.h3 }" class="div-enjoy" style="">Enjoy the rhythm of our live DJ while you dine with us</h3>
        <div class="div-btn-enjoy">
          <a flat style="" class="btn-enjoy" :href="getBranchConfigReserve" no-caps>Reserve</a>
        </div>
      </q-card-section>
    </div>

    <div flat class="events-card">
      <div class="events-origin">
        <EventsHome :event="event" :eventsHome="eventsHome" />
      </div>
    </div>

    <div style="height: 430px; width: 100%; z-index: 0; max-width: 100%">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3524.6472340562304!2d-82.48535962464477!3d27.943457876048008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c3debefa0b3b%3A0xe3e509aab9b9427d!2sAvenue%20Blu!5e0!3m2!1sen!2sus!4v1723755664071!5m2!1sen!2sus"
        width="100%"
        height="430"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div flat class="card-subscribe">
      <SubscribeViews data-aos="fade-up" />
    </div>
    <div class="fixed-menu">
      <ReserveBar />
    </div>
    <div class="fixed-call">
      <CallBar />
    </div>
  </div>
</template>
<script>
import "leaflet/dist/leaflet.css";

import { mapGetters } from "vuex";
import ReserveBar from "../../layouts/ReserveBar.vue";
import CallBar from "../../layouts/CallBar.vue";
import EventsHome from "../Home/EventsHome.vue";
import ReviewsHome from "../Home/ReviewsHome.vue";
import SelectedRecommended from "../Home/SelectedRecommended.vue";
import SubscribeViews from "../../layouts/SubscribeViews.vue";
import AtmosphereHome from "../Home/AtmosphereHome.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  components: {
    CallBar,
    ReserveBar,
    EventsHome,
    ReviewsHome,
    SelectedRecommended,
    SubscribeViews,
    AtmosphereHome,
  },
  data() {
    return {
      loadedProducts: false,
      styles: {},
      fontsVariables: [],
      zoom: 17,
      coordinates: [27.943429442146858, -82.48277397597482],

      title: "Avenue Blu | Live Entertainment Dining in Tampa",

      description:
        "Best restaurant atmosphere in Tampa, delicious American Caribbean food, and tasty drinks with live entertainment. Reserve now!",
      bestSellerProducts: [],
      allReviews: [],
      eventsHome: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTitle();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle();
    next();
  },
  methods: {
    setTitle() {
      document.title = this.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", this.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", this.description);
        document.head.appendChild(newMeta);
      }
    },

    changeTab(tab) {
      this.currentTab = tab;
    },
    fetchBusinessInfo() {
      this.$store.dispatch("business/fetchBusinessInfo").then((resp) => {
        const location = resp.location;
        if (location === "col") {
          this.$i18n.locale = "es";
        } else if (location === "us") {
          this.$i18n.locale = "en";
        }
        this.loading = false;
      });
    },

    route() {
      return this.$route;
    },
    scrollHandler(w) {
      if (w.position === 0 || w.position !== this.position) {
        if (w.position === 0) {
          this.visibilityInput = true;
          this.scrollIntern = false;
          this.$store.commit("menu/setScrollIntern", false);
        } else {
          this.visibilityInput = false;

          if (!this.scrollIntern) {
            this.scrollIntern = true;
            this.$store.commit("menu/setScrollIntern", true);
          }
        }
      }
      this.position = w.position;
    },

    fetchWelcome() {
      this.$store.dispatch("business/fetchEvents").then((resp) => {
        this.eventsHome = resp;
      });
      this.$store.dispatch("business/fetchReviews").then((resp) => {
        this.allReviews = resp;
      });
      this.$store.dispatch("menu/fetchProductsBestSeller").then((resp) => {
        this.bestSellerProducts = resp.data.filter((product) => product.best_seller === true);
        this.loadedProducts = true;
      });
    },
    fetchBranchInfo() {
      this.$store.dispatch("business/fetchBranchInfo");
      this.$store.dispatch("business/fetchBusinessInfo");
    },
    fetchBranchConfig() {
      this.$store.dispatch("business/fetchBranchConfig");
    },
    fetchBranchDetails() {
      this.$store.dispatch("business/fetchBranchDetails");
    },
  },
  computed: {
    ...mapGetters("business", [
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getEvents",
      "getReviews",
      "getStyles",
      "getBranchConfigReserve",
    ]),
    ...mapGetters("menu", ["geProductsBestSeller"]),
    directReviews() {
      return this.$store.state.business.reviews;
    },
  },
  created() {
    this.loading = true;
    this.fetchBranchInfo();
    this.fetchBranchConfig();
    this.fetchBranchDetails();
  },
  mounted() {

    // para cargar los estilos del badge de fomra dinamica
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://cdn6.localdatacdn.com/badges/restaurantji/css/badge-v3.3.css?v=48613";
    document.head.appendChild(link);

    // Esto es para cargar de forma sinamica el script
    const script = document.createElement("script");
    script.src = "https://cdn6.localdatacdn.com/badges/restaurantji/js/circletype.js";
    script.onload = () => {
      if (window.CircleType) {
        new window.CircleType(document.getElementById("circletype_v3_brand_name")).radius(225);
      } else {
        console.error("CircleType no está disponible en el contexto global.");
      }
    };
    document.head.appendChild(script);

    console.log("getBranchConfigReserve", this.getBranchConfigReserve);

    AOS.init();
    this.fetchBusinessInfo();
    this.fetchWelcome();
  },
};
</script>

<style lang="scss" scoped>
.div-recommend {
  display: flex;
  justify-content: center;
  background-color: #fdfdfd;
  padding-top: 64px;
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que el video cubra todo el contenedor */
  z-index: 1; /* Coloca el video detrás del contenido */
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  touch-action: none;
  z-index: 0;
}

.atmosphere-card {
  background-color: #f5f7f9;
}
.items-start {
  max-width: 1350px;
  margin: auto;
}
.div-btn-enjoy {
  padding-bottom: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-explore {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-left: 24px;
  font-family: Manrope;
  border-radius: 0 !important;
  height: 54px;
  width: 155px;
  font-weight: 400;
  color: $accent;
  background-color: transparent;
  border: 1px solid $accent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:active {
    color: $primary;
    background-color: $accent;
  }
  &:focus {
    border: 3px solid $accent;
    background-color: transparent;
    color: $accent;
  }
}
.btns-home {
  display: flex;
  justify-content: center;
}
.fixed-menu {
  display: none;
  height: 60px;
}
.fixed-call {
  display: none;
  height: 60px;
}
.events-origin {
  display: flex;
  animation: fadeInUp 1s ease-out;
}
.events-carousel {
  display: none;
}
.q-card {
  border-radius: 0;
  box-shadow: none;
}
.div-enjoy {
  line-height: normal;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  padding-bottom: 58px;
  font-size: 2rem;
  color: $accent;
}
.span-stars-card {
  font-size: 1.1rem;
  font-family: Manrope;
  padding-top: 46px;
  color: $primary;
}
.p-home {
  font-size: 1.25rem;
  font-family: Manrope;
  margin-bottom: 24px !important;
  text-align: center;
}
.h4-home {
  line-height: normal;
  font-size: 2.5rem;
  font-family: Prata;
  margin-bottom: 24px;

  text-align: center;
}
.q-card__section--vert {
  padding: 0 !important;
  margin: 0 !important;
}
.reviews-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 125px;
  padding-bottom: 200px;
  padding-left: 140px;
  padding-right: 140px;
  background-color: $background;
}
.cousine-card {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 677px;
  padding-left: 140px;
  padding-right: 140px;
  background-color: $background;
}
.events-card {
  padding-left: 140px;
  padding-right: 140px;
  padding-top: 180px;
  padding-bottom: 180px;
  background-color: $background;
  // height: 785px;
}
.btn-reserve {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Manrope;
  font-size: 1rem;
  background-color: $accent;
  color: $primary;
  text-align: center;
  width: 155px;
  border-radius: 0 !important;
  height: 54px;
  // padding-left: 20px;
  border: none;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    color: $primary;
    background-color: $accent;
  }
  &:focus {
    border: 2px solid $accent;
    background-color: black;
    color: $accent;
  }
}

.btn-learn {
  margin-top: 17px;
  background-color: #082136;
  color: white;
  text-align: center;
  width: 172px;
}

.btn-enjoy {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: $accent;
  color: $primary;
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  height: 51px;
  width: 171px;
  border-radius: 0 !important;
}
.card-info {
  background-color: #082136;
  color: white;
}
.card-subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/images/joinbg.webp");
  background-size: cover;
  // height: 452px;

  .text-subscribe {
    font-size: 2rem !important;
    color: #082136;
    margin-bottom: 0;
  }
}

.star-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.star-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.star-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}
.home-online {
  // width: 100vw;
  background-image: url("@/assets/images/backghome.webp");
  position: relative;
  height: 100vh; /* Ajusta la altura según sea necesario */
  max-height: 800px; /* Ajusta la altura máxima según sea necesario */
  display: flex;
  align-items: center;
  overflow: hidden; /* Asegura que el contenido no se desborde */
}
.card-enjoy {
  // background-image: url("@/assets/images/enjoybg.webp");
  // background-repeat: no-repeat;
  // // height: 480px;
  // display: flex;
  // justify-content: center; /* Centra horizontalmente */
  // align-items: center;
  // font-family: Prata;
  // background-size: cover;
  position: relative;
  height: 100vh; /* Ajusta la altura según sea necesario */
  max-height: 480px; /* Ajusta la altura máxima según sea necesario */
  display: flex;
  align-items: center;
  overflow: hidden; /* Asegura que el contenido no se desborde */
  justify-content: center;
}
.my-card {
  color: $accent;
  background-size: cover; /* Ajusta según sea necesario */
  background-position: center; /* Ajusta según sea necesario */
  width: 100%;
  height: 100%;
  border: 0 !important;
  text-align: left;
  // margin-top: 200px;
  padding-left: 140px;
  padding-right: 140px;
  max-width: 1309px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.stars-card {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;
  padding-top: 200px;
  padding-bottom: 140px;
  padding-left: 140px;
  padding-right: 140px;
  background-color: $background;
  color: $primary;

  .card-section {
    font-size: 1.5rem;
    font-family: Manrope;
    gap: 104px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    animation: fadeInUp 1s ease-out;
  }
}

@media screen and (max-width: 1024px) {
  .my-card {
    padding-left: 80px;
    padding-right: 80px;
  }
  .stars-card {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 120px;
    padding-bottom: 104px;
  }
  .cousine-card {
    padding-left: 80px;
    padding-right: 80px;
  }
  .events-card {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 0;
  }
  .reviews-card {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (min-width: 797px) and (max-width: 969px) {
  .events-card {
    padding-bottom: 0 !important;
    padding-top: 0;
  }
}

@media screen and (max-width: 796px) {
  .card-enjoy {
    background-position: center;
  }
  .events {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    text-align: left;
  }
  .home-online {
    height: 100dvh;
    max-height: 100dvh;
  }
  .div-enjoy {
    padding-top: 200px;
  }
  .div-btn-enjoy {
    padding-bottom: 200px;
  }
  .btns-home {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .fixed-menu {
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    right: 0;
    border-top: solid #dee1e4 2px;
  }
  .fixed-call {
    left: 0;
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    border-top: solid #dee1e4 2px;
  }
  .events-card {
    // height: 764px;
    padding-bottom: 0 !important;
  }

  .span-stars-card {
    font-size: 1.125rem;
  }
  .star-a {
    margin-top: 0;
  }

  .star-c {
    margin-bottom: 0;
  }
  .my-card {
    padding-left: 21px;
    padding-right: 21px;
    animation: fadeUp 0.7s ease-in-out;
  }

  // @keyframes fadeUp {
  //   0% {
  //     opacity: 0;
  //     transform: translateY(20px); /* Ajusta este valor para controlar la distancia de "subida" */
  //   }
  //   100% {
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }
  .stars-card {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 34px;
    padding-bottom: 34px;
    // height: 700px;
    .card-section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 48px;
    }
  }
  .cousine-card {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 34px;
    padding-bottom: 34px;
    // height: 1709px;
  }
  .events-card {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 34px;
    padding-bottom: 34px;
  }
  .reviews-card {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 34px !important;
    padding-bottom: 34px;
    // height: 1076px;
  }
  .h4-home {
    font-size: 2rem;
  }
  .div-enjoy {
    padding-left: 21px;
    padding-right: 21px;
    font-size: 1.5rem;
  }

  .btn-reserve {
    width: 100%;
  }
  .btn-explore {
    font-size: 1rem;
    margin-left: 24px;
    font-family: Manrope;
    border-radius: 0 !important;
    height: 54px;
    width: 100%;
    margin-left: 0 !important;
  }
  .p-home {
    font-size: 1.125rem;
  }
  .h4-home {
    font-size: 1.75rem;
  }
}
</style>
