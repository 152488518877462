export default {
  common: {
    back: "Atrás",
    menu: "Menú {restaurant}",
  },
  pages: {
    logout: {
      bye: "¡Vuelve pronto!",
      byeDescription: "Esperamos que hayas disfrutado tu experiencia en",
    },
    menu: {
      menus: "Menús",
      seeMenu: "Ver menú",
      searchProduct: "Buscar en menú",
      chooseOptions: "Elige { max_required } opción(es)",
      chooseAditions: "Elige { max_required } adición(es)",
      required: "Requerido",
      noProduct: "No pudimos encontrar un producto con ese nombre",
      noMenu: "Aún no hay un menú disponible para este Restaurante",
    },

    restaurant: {
      follow: "Seguir",
      review: "Calificar",
    },
    productlist: {
      notFound: "No pudimos encontrar el producto con ese nombre :",
    },

    entering: {
      title: "Bienvenido a ",
      numero: "Por favor ingresa tu número de telefono",
      politic: "Al continuar aceptas las siguientes políticas de privacidad",
      suscription: "Suscribete a { restaurant }, ultimas noticias y eventos",
      next: "Siguiente",
      skip: "Salta para ver el menú",
    },
    help: {
      title: "¿Necesitas ayuda?",
      subtitle: "Selecciona el tipo de ayuda que necesitas",
      request: "Solicita la cuenta",
      waiter: "Solicita un mesero",
    },
    mainlayout: {
      help: "Ayuda",
      menu: "Menú",
      orderOnline: "Ordenar online",
      reserve: "Reserva ahora",
    },
    days: {
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
    },
  },
};
