<template>
  <section
    id="search-options"
    :class="{
      'so-on-categories-visibility-design': !visibilityInput,
      'so-on-categories': visibilityInput,
    }"
    @click="collapseProfile"
  >
    <div v-if="buttons.length == 0 && !loading" class="so-non-menu">
      There is still no menu available for this restaurant menu online.
    </div>
    <div v-else-if="buttons.length !== 0 && !loading" class="so-on-container-categories">
      <div
        :class="{
          'so-categories-visibilityInput': !visibilityInput,
        }"
      >
        <div>
          <h5 class="h5-menu" style="">Our Menu</h5>
        </div>
        <div class="container-input-btns">
          <!-- v-if="menuGroups.length >= 5" -->
          <div class="so-on-menus">
            <q-btn
              no-caps
              style="border-radius: 0 !important"
              class="btn-menu-groups"
              v-for="(group, index) in menuGroups.slice(0, 3)"
              :key="index"
              @click="fetchMenu(group.menu_name, index)"
              :class="{ 'active-button': index === activeButtonIndex }"
            >
              {{ group.menu_name }}
            </q-btn>
          </div>
          <!-- <q-select v-model="model" :options="options" label="Standard" @update:model-value="onMenuSelect" /> -->
          <div class="expansion-menu-groups">
            <q-select
              ref="myqselect"
              v-model="selectedMenuGroup"
              label="Menu"
              :options="optionsArray"
              option-value="menu_name"
              option-label="menu_name"
              @update:model-value="fetchMenu(selectedMenuGroup)"
              class="expansion-item-menu-groups custom select"
              popup-content-class="mi-clase-personalizada"
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" :style="scope.selected ? { 'background-color': 'white' } : {}">
                  <q-item-section>
                    <q-item-label :class="{ 'selected-item': scope.selected }">{{ scope.opt }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="input so-on-input-container">
            <q-input
              borderless
              v-model="text"
              type="search"
              @input="filterByText"
              :placeholder="$t('pages.menu.searchProduct')"
              style="font-size: 1rem; font-family: Manrope"
            >
              <template v-slot:prepend>
                <q-icon class="icon-search" name="search" />
              </template>
            </q-input>
          </div>
        </div>
      </div>
      <div v-if="isFiltered == false" class="so-on-buttons">
        <ScrollableTabs :buttons="buttons" :moveToCategory="moveToCategory" :currentIndex="currentIndex" />
      </div>
    </div>
    <div v-show="loadingMenuProducts" class="q-pa-md flex flex-center loading-container">
      <div class="loading-q-circular">
        <q-circular-progress indeterminate rounded size="50px" color="white" class="q-ma-md" />
      </div>
    </div>
  </section>
</template>
<script>
import ScrollableTabs from "../components/ScrollableTabs.vue";
import { mapGetters } from "vuex";
import MobileDetect from "mobile-detect";

export default {
  components: {
    ScrollableTabs,
  },
  props: {
    setLoadingMenuProducts: {
      type: Function,
      required: true,
    },
    loadingMenuProducts: {
      type: Boolean,
      required: true,
    },

    activeTab: {
      type: Number,
      required: true,
    },
    // visibilityInput: {
    //   type: Boolean,
    //   required: true,
    // },
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isMobileDevice: false,
      scrollThreshold: 0,
      optionsArray: [],

      selectedMenuGroup: "",
      coordinates: [],
      buttons: [],
      text: "",
      tab: "",
      isActive: false,
      categoriesTop: false,
      currentIndex: 0,
      activeButtonIndex: 0,
      loading: true,
      visibilityInput: true,
      showMenuGroup: false,
    };
  },
  watch: {
    activeTab(newVal) {
      if (newVal !== null && newVal !== undefined) {
        this.activeButtonIndex = newVal;
      }
    },

    menuGroups(newVal) {
      // Cuando menuGroups cambie, actualiza selectedMenuGroup con el primer elemento, si existe
      if (newVal && newVal.length > 0) {
        this.selectedMenuGroup = newVal[0].menu_name;
        this.optionsArray = newVal.map((item) => item.menu_name);
      }
    },
    text() {
      this.filterByText();
    },
    allProducts() {
      if (this.allProducts.length > 0) {
        this.createCategory();
      }
    },

    position() {
      if (this.$refs.myqselect) {
        this.$refs.myqselect.hidePopup(); // Cierra el q-select
      }

      if (!this.categoriesTop) {
        if (!this.buttons.length > 0) return;
        this.buttons.forEach((button, index) => {
          const categoryElement = document.getElementById(button.ref);
          this.buttons[index].position = categoryElement.offsetTop;
        });
        this.categoriesTop = true;
      }

      const scrollPosition = window.scrollY;
      let activated = false;

      this.buttons.forEach((category, categoryIndex) => {
        if (scrollPosition >= category.position - 155 && !activated) {
          this.buttons.forEach((button, index) => {
            let activeAlgo = categoryIndex === index;
            button.isActive = activeAlgo;

            this.currentIndex = categoryIndex;
          });
          this.tab = category.text;
        }
      });
    },
  },
  mounted() {
    this.filterByText();
    this.createCategory();

    window.addEventListener("scroll", this.scrollHandler, { passive: true });
  },
  created() {
    const md = new MobileDetect(window.navigator.userAgent);
    this.isMobileDevice = md.mobile() !== null || md.tablet() !== null;
    this.scrollThreshold = this.isMobileDevice ? 65 : 0;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollHandler, { passive: true });
  },
  computed: {
    ...mapGetters({
      isFiltered: "menu/getIsFiltered",
      allProductsFiltered: "menu/getProductsFiltered",
      allProducts: "menu/getAllProducts",
      menuGroups: "menu/getMenuTime",
    }),
  },
  methods: {
    scrollHandler() {
      if (this.$refs.myqselect) {
        this.$refs.myqselect.hidePopup(); // Cierra el q-select
      }
      const scrollPosition = window.scrollY;
      if (scrollPosition > this.scrollThreshold) {
        this.visibilityInput = false;
      } else {
        this.visibilityInput = true;
      }
    },
    fetchMenu(menuName, index) {
      this.setLoadingMenuProducts(true);
      this.activeButtonIndex = index;
      this.$store.dispatch("menu/fetchMenuTime", { name: menuName }).then(() => {
        this.setLoadingMenuProducts(false);
      });
      this.selectedMenuGroup = menuName;
      this.$router.push({ name: "menu-name", params: { menuName: menuName } });
    },
    toogleCategories(id) {
      for (let index = 0; index < this.buttons.length; index++) {
        if (index == id) {
          this.buttons[index].active = true;
          this.moveToCategory(index);
        } else {
          this.buttons[index].active = false;
        }
      }
    },
    moveToCategory(index) {
      this.$nextTick(() => {
        const categoryRef = this.buttons[index].ref;
        const categoryElement = document.getElementById(categoryRef);
        if (categoryElement) {
          const SearchOptions = document.getElementById("search-options");
          let offsetPosition = categoryElement.offsetTop;
          if (this.visibilityInput) {
            offsetPosition = offsetPosition - SearchOptions.offsetHeight - 74;
          } else {
            offsetPosition = offsetPosition - 155;
          }
          if ("scrollBehavior" in document.documentElement.style) {
            window.scrollTo({ top: offsetPosition, behavior: "smooth" });
          } else {
            window.scrollTop = offsetPosition;
          }
        }
        this.buttons.forEach((button, i) => {
          button.active = i === index;
          button.isActive = i === index;
        });
      });
    },
    createCategory() {
      this.buttons = [];
      this.$nextTick(() => {
        for (let index = 0; index < this.allProductsFiltered.length; index++) {
          if (this.allProductsFiltered[index].products.length > 0) {
            const categoryRef = this.allProductsFiltered[index].name.replace(/[\s/]+/g, "");

            this.buttons.push({
              text: this.allProductsFiltered[index].name,
              active: false,
              id: index,
              ref: categoryRef,
              isActive: index === 0,
            });
          }
        }

        if (this.buttons.length == 0) {
          return;
        }
        this.buttons[0].isActive = true;
        this.tab = this.buttons[0].text;
        this.filterByText();
        this.loading = false;
      });
    },
    filterByText() {
      this.$store.commit("menu/filterByText", this.text);
    },
  },
};
</script>
<style lang="scss">
.loading-q-circular {
  margin-top: 100px;
}
.h5-menu {
  text-align: left;
  color: $accent;
  margin-bottom: 17px;
  margin-top: 24px;
  font-family: Prata;
  font-size: 1.375rem;
}
.icon-search {
  color: $accent;
  height: 25px !important;
  padding-right: 3px;
}
.q-select__dialog label {
  display: none;
}
.selected-item {
  background-color: $accent;
  color: #071b2c;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.mi-clase-personalizada {
  background-color: #071b2c !important;
  border: 1px solid $accent;
  color: $accent;
  font-size: 1rem;
  font-family: Manrope;
}
.q-field__append {
  color: $accent !important;
  width: 54px;
}
.so-on-input-container .q-field__native {
  color: $accent !important;
  font-family: Manrope;
  font-size: 1rem;
}
.expansion-menu-groups .q-field__native {
  color: $accent !important;
  font-size: 1rem;
  font-family: Manrope;
  text-align: left;
  width: 100%;
  padding-left: 20px !important;
}
.q-field__label {
  color: $accent !important;
  font-family: Manrope;
  font-size: 0.875rem;
  padding-left: 27px !important;
}

.expasion-item-menu-groups {
  color: $accent !important;
  font-size: 1rem;
  font-family: Manrope;
  text-align: left;
  width: 100%;
}
.expansion-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.expansion-menu-groups {
  z-index: 99;
  display: none;
  .q-expansion-item__container {
    border: 1px solid $accent;
    width: 100%;
  }
}
.btn-expansion-menu-groups {
  color: $accent !important;
  margin-right: 10px;
  width: 146px !important;
  height: 45px !important;
  border: 1px solid $accent !important;
}
.btn-menu-groups {
  color: $accent !important;
  margin-right: 10px;
  width: 146px !important;
  height: 45px !important;
  border: 1px solid $accent !important;
}
.so-categories-visibilityInput {
  display: none;
}
.so-on-menus {
  font-size: 1rem;
  font-family: Manrope;
}
.container-input-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  max-width: 100vw;
}
.search-color {
  color: $accent;
}
.active-button {
  background: $accent !important;
  color: $primary !important;
}
.so-on-input-container {
  flex: 1;
  width: auto;
  max-width: 465px;
  color: $accent;
  border-radius: 0 !important;
}
.btn-cat {
  $accent-space: nowrap;
  background-color: var(--q-bg-nav);
  border: none;
  color: $accent;
  margin-top: 10px;

  padding: 1px 8px 13px;
  &:active {
    color: var(--q-secondary);
  }
}

.so-on-container-categories .q-field__control {
  background-color: #071b2c !important;
  border: 1px solid $accent;
  height: 45px;
  &:focus-within {
    border: 1px solid $accent;
  }
  .q-field__prepend {
    height: 48px;
    padding-left: 7px;
  }
}

.so-on-container-categories input {
  color: $accent !important;
}

.so-non-menu {
  color: $accent;
  width: 80%;
}
.so-on-categories-visibility-design {
  position: sticky; // esta position necesita una altura especifica, no auto
  top: 83px;
  height: 74px;
  background-color: #082136;
  z-index: 99;
  padding-top: 16px;
  width: 79vw;
  // left: 163px;
  max-width: 1039px;
  margin: auto;
}
.so-on-categories {
  position: sticky; // esta position necesita una altura especifica, no auto
  top: 83px;
  z-index: 1;
  width: calc(100vw - 280px);
  background-color: #082136;
  padding-top: 16px;
  // max-width: 100vw;
  margin: auto;
  max-width: 1029px;
}
.so-on-container-categories {
  // max-width: 100vw;
  overflow-x: hidden;
  width: 100%;
  color: var(--q-body-color);

  border-bottom: 1px solid rgba(219, 236, 250, 0.1);
}
.so-payfud-input {
  height: 48px;
}

.so-on-buttons {
  display: flex;
  width: 100%;
  overflow: auto;
  margin-top: 6px;

  .q-tabs__content {
    padding-left: 13px !important;
    padding-right: 13px;
  }
}

@media (min-width: 1025px) and (max-width: 1115px) {
  .container-input-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .so-on-input-container {
    width: 100%;
    max-width: 316px;
    color: $accent;
    border-radius: 0 !important;
  }
}
@media (min-width: 883px) and (max-width: 977px) {
  .container-input-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 770px;
    width: 100%;
  }
  .so-on-input-container {
    width: 100%;
    max-width: 316px;
    color: $accent;
    border-radius: 0 !important;
  }
  .so-on-container-categories {
    width: 706px;
  }
}

@media screen and (max-width: 1024px) {
  .so-on-categories-visibility-design {
    width: calc(100vw - 160px);
  }
  .container-input-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  .so-on-container-categories {
    width: 100%;
  }

  .so-on-input-container {
    max-width: 376px;
  }
  .so-on-menus {
    display: flex;
    width: 384px;
  }
  .btn-menu-groups {
    width: 120px;
  }
  .so-on-categories {
    width: calc(100vw - 160px);
  }
}
@media screen and (max-width: 883px) {
  .so-on-categories {
    width: calc(100vw - 45px);
  }

  .so-on-input-container {
    max-width: 100%;
  }
  .expansion-menu-groups .q-item__section--side {
    color: $accent;
  }
  .so-on-container-categories {
    width: 100%;
  }
  .expansion-menu-groups {
    z-index: 99;
    display: block;
    .q-expansion-item__container {
      border: 1px solid $accent;
      width: 100%;
      background-color: #071b2c;
    }
  }
  .so-on-menus {
    display: none;
  }
  .container-input-btns {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    // min-width: 328px;
    width: 100%;
  }
  .so-on-container-categories .q-field__control {
    width: 100%;
  }
  .so-on-categories-visibility-design {
    position: sticky; // esta position necesita una altura especifica, no auto
    top: 60px;
    height: 74px;
    width: calc(100vw - 38px);
    background-color: #082136 !important;
    z-index: 99;
    padding-top: 16px;
  }
}
</style>
