<template>
  <q-card-section class="section-events" style="margin: auto !important">
    <div class="div-titles">
      <div class="title-card">
        <h2 :style="{ ...getStyles.h2, color: getStyles.h1?.colorSecondary }" class="h4-events">Upcoming Events</h2>
      </div>
      <div class="title-card-p">
        <router-link :to="{ name: 'events' }" class="p-events" style="margin-bottom: 0 !important">
          Explore events <q-icon name="chevron_right" style="font-size: 24px"></q-icon>
        </router-link>
      </div>
    </div>
    <div class="events" ref="events_home">
      <q-card flat bordered class="event-card" v-for="(event, index) in eventsHome" :key="index" @click="learnMore(event.id)">
        <img :src="event.image" style="height: 196px" alt="img-event" class="img-event" loading="lazy" />

        <q-card-section class="col-grow justify-between card-section">
          <div class="">
            <h3 :style="{ ...getStyles.h3, color: getStyles.h3?.colorSecondary }" class="text-event-title">{{ event.title }}</h3>
            <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" class="text-subtitle2">
              {{ event.description }}
            </p>
          </div>

          <div class="info-icons-container">
            <div class="info-icons">
              <q-icon name="event" size="24px" style="margin-right: 10px"></q-icon>
              <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" style="margin: 0">{{ event.schedule }}</p>
            </div>
            <div class="info-icons-schedule-arrow">
              <div class="info-icons-schedule">
                <q-icon name="schedule" size="24px" style="margin-right: 10px"></q-icon>
                <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" style="margin: 0">
                  {{ event.start_hour }} - {{ event?.end_hour }}
                </p>
              </div>
              <div>
                <q-icon class="learn-more" name="chevron_right" size="24px"></q-icon>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <router-link :to="{ name: 'events' }" class="p-events-mobile">
      Explore events <q-icon name="chevron_right" style="font-size: 24px"></q-icon>
    </router-link>
  </q-card-section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    eventsHome: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters("business", ["getReviews", "getStyles"]),
  },
  mounted() {},
  methods: {
    goToEvents() {
      this.$router.push({ name: "events" }); // Usa la ruta correcta para tu componente de menú
    },

    learnMore(eventId) {
      this.$store.dispatch("business/fetchEventsDetail", eventId).then(() => {
        this.$router.push({ name: "events-detail", params: { eventId } });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.div-titles {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 32px;
  height: 79px;
}
.p-events-mobile {
  display: none;
}
.img-event {
  height: 196px;
  object-fit: cover;
}
.card-section {
  flex: 1 1 100%;
  min-width: 0;
}

.learn-more {
  cursor: pointer;
}
.q-card__section--vert {
  padding: 0;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 31px;
  padding-bottom: 26px;
}
.q-card--bordered {
  border: 1px solid $border;
}
.section-events {
  display: flex;
  flex-direction: column;
  max-width: 1067px;
  /* Permite que los elementos se ajusten en pantallas más pequeñas */
  // justify-content: space-between; /* Alinea los elementos a los extremos en pantallas más grandes */
}

.title-card,
.title-card-p {
  display: flex;
  align-items: center; /* Alinea los elementos internos verticalmente al centro */

  justify-content: space-between;
}
.title-card-p {
  // padding-top: 238px;
  .p-events {
    color: $primary;
    margin-bottom: 0 !important;
    text-decoration: none;
    font-family: Manrope;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: underline;
    }
    &:focus {
      background-color: transparent;
      border: 3px solid $primary;
      text-decoration: underline;
    }
  }
}
.h4-events {
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  color: $primary;
  font-size: 2rem;
  font-family: Prata;
}

.info-icons-schedule-arrow {
  display: flex;
  justify-content: space-between;
}
.info-icons-schedule {
  font-size: 1.125rem;
  font-family: Manrope;
  display: flex;
  justify-self: start;
  color: $primary;
}
.btn-learn {
  text-align: center;
  width: 100%;
  height: 51px;
  border-radius: 0 !important;
  font-size: 1.125rem;
  font-family: Manrope;
  color: $accent;
  background-color: $secondary;
}
.info-icons {
  font-size: 1.125rem;
  font-family: Manrope;
  padding-bottom: 15px;
  color: $primary;
  display: flex;
  justify-content: start;
}
.text-subtitle2 {
  height: 49px;

  color: $primary;
  font-size: 1rem;
  font-family: Manrope;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-event-title {
  color: $primary;
  font-size: 1.5rem;
  font-family: Prata;
  padding-bottom: 4px;
  margin-bottom: 0;
  margin-top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.event-card {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  border-radius: 0 !important;

  overflow: hidden;
}
.title-card {
  display: flex;
  justify-content: space-between; /* Centra horizontalmente */
  align-items: center;
}
.events {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .event-card {
    // height: 499px;
    width: 100%;
  }
  .btn-learn {
    width: 100%;
    height: 51px;
  }
  .text-event-title {
    padding-bottom: 14px;
  }
}
@media screen and (max-width: 1115px) {
  .event-card {
    // height: 499px;
    width: 100%;
  }
  .btn-learn {
    width: 100%;
    height: 51px;
  }

  .events-card {
    height: 699px;
  }
}

@media screen and (max-width: 883px) {
  .div-titles {
    margin-bottom: 32px;
  }
  .p-events {
    display: none;
  }
  .p-events-mobile {
    text-decoration: none;
    width: 143px;
    text-align: left;
    display: block;
    color: $primary;
    margin-bottom: 36px;
    margin-top: 29px;
    padding: 0;
    font-family: Manrope;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.4);
      text-decoration: underline;
    }
    &:focus {
      background-color: transparent;
      border: 3px solid $primary;
      text-decoration: underline;
    }
  }
  .events {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    text-align: left;
  }
}
.h4-events {
  padding-top: 0;
  margin: 0 !important;
}
</style>
