<template>
  <div>
    <div class="section-event-detail">
      <div class="bread-div">
        <!-- <q-breadcrumbs>
          <q-breadcrumbs-el @click="goToBackEvents" label="Events" />
          <q-breadcrumbs-el :label="getEventsDetail[0]?.title" />
        </q-breadcrumbs> -->
        <ul class="breadcrumb">
          <li><a class="a-events" href="#" @click="goToBackEvents">Events</a></li>
          <li>
            <a class="a-title" href="#">{{ getEventsDetail[0]?.title }}</a>
          </li>
        </ul>
      </div>
      <img :src="getEventsDetail[0]?.image" class="img-event-detail" loading="lazy" />
      <div style="display: flex" class="container">
        <div style="flex: 1" class="info-event">
          <h1 :style="{ ...getStyles.h1, color: getStyles.h1?.colorSecondary }" class="h1-event-detail">
            {{ getEventsDetail[0].title }}
          </h1>
          <div class="info-icons-schedule">
            <div class="info-icon-date">
              <q-icon name="event" size="24px" style="margin-right: 10px"></q-icon>
              <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }">{{ getEventsDetail[0].schedule }}</p>
            </div>
            <div class="info-icon-hour">
              <q-icon name="schedule" size="24px" style="margin-right: 10px"></q-icon>
              <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }">
                {{ getEventsDetail[0].start_hour }} - {{ getEventsDetail[0]?.end_hour }}
              </p>
            </div>
          </div>
          <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" class="descrip-event">
            {{ getEventsDetail[0].description }}
          </p>
          <h4 :style="{ ...getStyles.h4, color: getStyles.h4?.colorSecondary }" class="h-event-detail">About event</h4>
          <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" class="descrip-ext-event">
            {{ getEventsDetail[0].long_descripcion }}
          </p>
          <!-- <h5>Ejemplo de titular</h5>
          <ul>
            <li><p>list 1</p></li>
            <li><p>list 2</p></li>
            <li><p>list 3</p></li>
          </ul>
          <h2 class="h-event-detail">Ejemplo del titular</h2> -->
        </div>
        <div class="card-book">
          <div>
            <div>
              <p :style="{ ...getStyles.p, color: getStyles.p?.colorSecondary }" class="p-free">Free</p>
              <q-btn flat no-caps @click="goToReservationPage()" class="btn-book">Reserve</q-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fixed-contact">
    <ContactUs />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContactUs from "../layouts/ContactUs.vue";
import { parseISO, format } from "date-fns";
import { en } from "date-fns/locale";
export default {
  components: {
    ContactUs,
  },
  data() {
    return {
      eventsHome: [],
    };
  },
  computed: {
    ...mapGetters("business", [
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getEventsDetail",
      "getStyles",
    ]),
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), "EEE, LLLL d, yyyy", { locale: en });
    },
    goToReservationPage() {
      const reserveLink = this.getBranchConfig?.reserve_link;

      if (reserveLink) {
        window.open(reserveLink, "_blank");
      }
    },
    goToBackEvents() {
      this.$router.push({ name: "events" });
    },
  },
  crerated() {
    // const business = process.env.BUSINESS;
    // const id = process.env.BRANCH;
    // this.$store.commit("business/setBusinessName", business);
    // this.$store.commit("business/setIdBranch", id);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.a-events {
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: #01447e;
    text-decoration: underline;
  }
}
.a-title {
  color: $primary;
  text-decoration: none;
}
/* Style the list */
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

.fixed-contact {
  position: fixed;
  height: 54px;
  top: 88px;
  width: 100%;
  z-index: 1000;
}
.bread-div {
  font-size: 1rem;
  font-family: Manrope;
  color: $primary;
  margin-bottom: 38px;
  padding-top: 73px;
  cursor: pointer;
  text-align: left;
}
.descrip-ext-event {
  font-size: 1.125rem;
  font-family: Manrope;
}
.descrip-event {
  font-size: 1rem;
  font-family: Manrope;
}
.p-free {
  color: $primary;
  font-size: 1.25rem;
  font-family: Manrope;
}
.btn-book {
  background-color: $secondary;
  color: $accent;
  width: 277px;
  height: 43px;
  font-family: Manrope;
  font-size: 1rem;
}
.card-book {
  height: 192px;
  width: 342px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $border;
}
.info-event {
  text-align: left;
  .h1-event-detail {
    margin-top: 0;
    font-size: 2.5rem;
  }
}
.info-icons-schedule {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  text-align: left;
  .info-icon-date {
    font-size: 1.125rem;
    font-family: Manrope;
    padding-bottom: 15px;
    color: $primary;
    display: flex;
    justify-content: start;
  }
  .info-icon-hour {
    font-size: 1.125rem;
    font-family: Manrope;
    padding-bottom: 20px;
    color: $primary;
    display: flex;
    justify-content: start;
  }
}
.img-event-detail {
  width: 100%;
  height: 329px;
  background-size: cover;
  padding-bottom: 61px;
  object-fit: cover;
}
.section-event-detail {
  background-color: $background;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 100px;
  padding-top: 144px;
}
@media screen and (max-width: 1024px) {
  .section-event-detail {
    padding-left: 80px;
    padding-right: 80px;
  }
  .card-book {
    height: 192px;
    width: 310px;
    align-items: center;
    border: 1px solid $border;
  }
}
@media screen and (max-width: 883px) {
  .fixed-contact {
    position: fixed;
    height: 68px;
    top: 59px;
    width: 100%;
    z-index: auto;
  }
  .section-event-detail {
    display: flex;
    flex-direction: column;
    align-items: center; // Asegura que los elementos se centren si necesitas.
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .img-event-detail {
    width: 100%;
    height: 329px;
    background-size: cover;
    padding-bottom: 61px;
  }
  .section-event-detail {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 130px;
  }
  .bread-div {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    text-align: left;
  }
}
</style>
