<template>
  <div class="tabs-container">
    <button
      class="btn-left"
      v-if="showLeftArrow"
      @touchstart.prevent="startScrolling('left')"
      @touchend="stopScrolling"
      @click="scroll('left')"
      @mousedown="startScrolling('left')"
      @mouseup="stopScrolling"
      @mouseleave="stopScrolling"
    >
      <span class="material-symbols-outlined arrow-icon-left"> chevron_left </span>
    </button>
    <div
      class="tabs-wrapper"
      ref="tabsWrapper"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      @scroll="handleScroll"
    >
      <div
        v-for="(button, index) in buttons"
        :key="index"
        @click="activateTab(index)"
        :class="{ active: button.isActive }"
        class="tab"
      >
        {{ button.text }}
      </div>
    </div>
    <!-- v-if="showRightArrow" -->
    <button
      class="btn-right"
      @touchstart.prevent="startScrolling('right')"
      @touchend="stopScrolling"
      @click="scroll('right')"
      @mousedown="startScrolling('right')"
      @mouseup="stopScrolling"
      @mouseleave="stopScrolling"
    >
      <span class="material-symbols-outlined arrow-icon-right" :class="{ 'is-faded': !showRightArrow }"> chevron_right </span>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    moveToCategory: {
      type: Function,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      startX: 0,
      currentX: 0,
      isDragging: false,
      activeTab: 0,
      showLeftArrow: false,
      showRightArrow: false,
      scrollInterval: null,
    };
  },
  mounted() {
    this.updateArrows(); // Call it once when the component is mounted
  },
  watch: {
    activeTab: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.updateArrows();
          this.centerActiveTab();
        });
      },
    },
    currentIndex() {
      this.activeTab = this.currentIndex;
    },
  },
  methods: {
    startScrolling(direction) {
      if (this.scrollInterval) clearInterval(this.scrollInterval);
      // Comenzar el intervalo
      this.scrollInterval = setInterval(() => {
        this.scroll(direction);
      }, 10); //velocidad del desplazamiento
    },
    stopScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].pageX;
      this.isDragging = true;
    },
    handleTouchMove(event) {
      if (!this.isDragging) return;
      this.currentX = event.touches[0].pageX;
      let difference = this.startX - this.currentX;
      this.$refs.tabsWrapper.scrollLeft += difference;
      this.startX = this.currentX; // Update startX for the next move event
    },
    handleTouchEnd() {
      this.isDragging = false;
    },
    updateArrows() {
      if (!this.$refs.tabsWrapper) return;

      let wrapper = this.$refs.tabsWrapper;
      this.showLeftArrow = wrapper?.scrollLeft > 0;
      this.showRightArrow = wrapper?.scrollLeft < wrapper.scrollWidth - wrapper.offsetWidth;
    },
    handleScroll() {
      this.updateArrows();
    },
    activateTab(index) {
      this.activeTab = index;
      this.moveToCategory(index);
    },
    scroll(direction) {
      let amount = 5; // saltos de desplazamiento
      if (!this.$refs.tabsWrapper) return;
      if (direction === "left") {
        this.$refs.tabsWrapper.scrollLeft -= amount;
      } else if (direction === "right") {
        this.$refs.tabsWrapper.scrollLeft += amount;
      }
      // this.$nextTick(this.updateArrows)
    },
    centerActiveTab() {
      let wrapper = this.$refs.tabsWrapper;
      let tabs = wrapper.children;
      let activeTab = tabs[this.activeTab];
      let center = wrapper.offsetWidth / 2 - activeTab.offsetWidth / 2;
      let endPosition = activeTab.offsetLeft - center;
      let currentPosition = wrapper.scrollLeft;
      let changeInPosition = endPosition - currentPosition;
      let startTime = performance.now();
      let duration = 300; // Duración en milisegundos
      function animateScroll(currentTime) {
        let elapsed = currentTime - startTime;
        let progress = Math.min(elapsed / duration, 1);
        // Cálculo del desplazamiento basado en una función de facilidad para una transición más suave
        let easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
        let position = currentPosition + easeInOutQuad(progress) * changeInPosition;
        wrapper.scrollLeft = position;
        if (progress < 1) requestAnimationFrame(animateScroll);
      }
      requestAnimationFrame(animateScroll);
    },
  },
};
</script>
<style lang="scss" scoped>
.is-faded {
  opacity: 0.6; /* Ajusta este valor según necesites */
}
.arrow-icon-left {
  color: white;
  font-size: 35px;
  margin-left: -7px;
}
.arrow-icon-right {
  color: white;
  font-size: 35px;
  margin-right: -7px;
}
.btn-right {
  position: absolute;
  right: 0;
  border: none;
  background: linear-gradient(143deg, hsla(0, 0%, 8%, 0), #082136 50%);
  padding-left: 30px;
  height: 46px;
}
.btn-left {
  position: absolute;
  left: 0;
  border: none;
  background: linear-gradient(90deg, #082136 50%, transparent);
  padding-right: 30px;
  height: 46px;
}
.tabs-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.tabs-wrapper {
  display: flex;
  overflow: hidden;
  width: 96%;
  white-space: nowrap;
  transition: transform 0.3s ease;
  height: 39px;
}
.tab {
  font-family: Prata;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  padding: 6px 0 10px;
  margin-right: 18px;
  color: white;
}
.tab:last-child {
  border-right: none;
}
.tab.active {
  color: white;
  border-bottom: 2px solid white !important; /* Agregar un borde inferior de color secundario */
}
@media screen and (min-width: 883px) {
  .arrow-icon-left {
    color: white;
    font-size: 35px;
    margin-left: -18px;
  }
  .arrow-icon-right {
    color: white;
    font-size: 35px;
    margin-right: -18px;
  }
  .btn-right {
    position: absolute;
    right: 0;
    border: none;

    padding-left: 30px;
    height: 46px;
  }
  .btn-left {
    position: absolute;
    left: 0;
    border: none;

    padding-right: 30px;
    height: 46px;
  }
}
</style>
