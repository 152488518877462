import "./registerServiceWorker";
import App from "./App.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import { createApp } from "vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import i18n from "./locales/i18n";
import "@fontsource/manrope";
import "@fontsource/prata";

const userLanguage = navigator.language.slice(0, 2); // Extract the first two characters for language code
// Ensure that the user's language preference is supported in your i18n configuration
if (i18n.global && i18n.global.availableLocales && i18n.global.availableLocales.includes(userLanguage)) {
  i18n.global.locale.value = userLanguage;
}
// import { mdiAccount } from "@mdi/js";

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(i18n)
  
  .mount("#app");
