import { createStore } from "vuex";
import business from "./business";
import menu from "./menu";
import clients from "./clients";

export default createStore({
  modules: {
    business,
    menu,
    clients,
  },
});
