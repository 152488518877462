import { createRouter, createWebHistory } from "vue-router";

import MenuView from "../views/Menu/components/MenuView.vue";
import WelcomeHome from "../views/Home/WelcomeHome.vue";
import AtmosphereView from "../components/AtmosphereView.vue";
import ParkingView from "../components/ParkingView.vue";
import AboutUs from "../components/AboutUs.vue";
import EventsView from "../components/EventsView.vue";
import EventsDetails from "../components/EventsDetails.vue";

const routes = [
  { path: "/", name: "home", component: WelcomeHome },
  { path: "/menu", name: "menu", component: MenuView },
  { path: "/menu/:menuName", name: "menu-name", component: MenuView },
  { path: "/faqs", name: "faqs", component: ParkingView },
  { path: "/atmosphere", name: "atmosphere", component: AtmosphereView },
  { path: "/story", name: "story", component: AboutUs },
  { path: "/events", name: "events", component: EventsView },
  { path: "/events/:eventId", name: "events-detail", component: EventsDetails },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
export { routes };
