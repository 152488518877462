<template>
  <div v-show="!loading" id="menu-view" ref="scrollContainer" class="menu-layout full-width" @scroll="scrollHandler">
    <SearchOptions
      :position="position"
      :visibilityInput="visibilityInput"
      :activeTab="activeTab"
      :loadingMenuProducts="loadingMenuProducts"
      :setLoadingMenuProducts="setLoadingMenuProducts"
    />
    <ProductList :onlyRead="false" :visibilityInput="visibilityInput" :loadingMenuProducts="loadingMenuProducts" />
  </div>
  <div v-show="loading" class="q-pa-md flex flex-center">
    <div class="loading">
      <q-circular-progress indeterminate rounded size="50px" color="white" class="q-ma-md" />
    </div>
  </div>
  <div v-show="!loading">
    <div class="fixed-menu">
      <ReserveBar />
    </div>
    <div class="fixed-call">
      <CallBar />
    </div>
    <q-card class="card-subscribe">
      <SubscribeViews />
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { setCssVar } from "quasar";
import CallBar from "../../../layouts/CallBar.vue";
import { mapState } from "vuex";
import SubscribeViews from "../../../layouts/SubscribeViews.vue";
import SearchOptions from "../components/SearchOptions.vue";
import ProductList from "../components/ProductList.vue";
import ReserveBar from "../../../layouts/ReserveBar.vue";
export default {
  components: {
    SearchOptions,
    ProductList,
    SubscribeViews,
    ReserveBar,
    CallBar,
  },
  data() {
    return {
      title: "American Caribbean Restaurant Menu",
      description:
        "Explore our American Caribbean restaurant menu! Enjoy a mix of classic American dishes and vibrant Caribbean flavors. Join us for a delicious culinary experience that blends the best of both worlds",
      loadingMenuProducts: false,
      scrollPosition: 0,
      loading: true,
      position: 0,
      activeTab: null,
      menuName: "",
      visibilityInput: true,
      scrollIntern: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTitle();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle();
    next();
  },
  computed: {
    ...mapGetters("business", ["getBranchInfo", "getBranchConfig", "getBusinessInfo", "getBranchDetails"]),
    ...mapGetters("menu", ["getAllProducts", "getMenuNameParams"]),
    ...mapState(["business", "menu"]),
  },
  methods: {
    setTitle() {
      document.title = this.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", this.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", this.description);
        document.head.appendChild(newMeta);
      }
    },
    setLoadingMenuProducts(status) {
      this.loadingMenuProducts = status;
    },
    fetchBusinessInfo() {
      this.$store.dispatch("business/fetchBusinessInfo").then((resp) => {
        const location = resp.location;
        if (location === "col") {
          this.$i18n.locale = "es";
        } else if (location === "us") {
          this.$i18n.locale = "en";
        }
      });
    },

    route() {
      return this.$route;
    },
    scrollHandler(w) {
      if (w.position === 0 || w.position !== this.position) {
        if (w.position === 0) {
          this.visibilityInput = true;
          this.scrollIntern = false;
          this.$store.commit("menu/setScrollIntern", false);
        } else {
          this.visibilityInput = false;

          if (!this.scrollIntern) {
            this.scrollIntern = true;
            this.$store.commit("menu/setScrollIntern", true);
          }
        }
      }
      this.position = w.position;
    },
    fetchBranchInfo() {
      this.$store.dispatch("business/fetchBranchInfo");
      this.$store.dispatch("business/fetchBusinessInfo");
    },
    fetchBranchConfig() {
      this.$store.dispatch("business/fetchBranchConfig");
      // .then((resp) => {
      //   if (resp.status == 200) {
      //     // const styles = resp.data[0];
      //     // setCssVar("secondary", styles?.color);
      //     // setCssVar("card_stroke", styles?.card_stroke);
      //     // setCssVar("instagram", styles?.instagram);
      //     // setCssVar("review", styles?.review || "#DBB244");
      //     // setCssVar("bg-options", styles?.background_options);
      //     // setCssVar("bg-search", styles?.background_search);
      //     // setCssVar("bg-products-item", styles?.background_products_item);
      //     // setCssVar("bg-products", styles?.background_products);
      //     // setCssVar("bg-modal", styles?.background_modal);
      //     // setCssVar("bg-tabs", styles?.background_tabs);
      //     console.log("todo good");
      //   }
      // });
    },
    fetchBranchDetails() {
      this.$store.dispatch("business/fetchBranchDetails");
      // .then((resp) => {
      //   if (resp.status == 200) {
      //     // const styles = resp.data || resp.detail || {};
      //     // setCssVar("bg-color", styles?.detail?.color_background); //esto es el background
      //     // setCssVar("disable-btn", styles?.detail?.disable_buttons); // esto para btn desactivados
      //     // setCssVar("body-color", styles?.detail?.body_color); // esto para los subtitulos
      //     // setCssVar("body-font", styles?.detail?.body_type);
      //     // setCssVar("title-font", styles?.detail?.title_type);
      //     // setCssVar("bg-nav", styles?.detail?.background_navigation);
      //     // setCssVar("bg-contrast", styles?.detail?.background_contrast);
      //     console.log("todo good");
      //   }
      // });
    },
    fetchMenu(menuNameParams) {
      this.$store.dispatch("menu/fetchMenuTimeAll").then(() => {
        const menuTime = this.$store.getters["menu/getMenuTime"];

        const sortedMenuTime = menuTime.sort((a, b) => a.menu_position - b.menu_position);

        this.menuName = menuNameParams || sortedMenuTime[0].menu_name;

        this.$store.dispatch("menu/fetchMenuTime", { name: this.menuName }).then(() => {
          this.loading = false;
        });
        const indice = sortedMenuTime.findIndex((elemento) => elemento.menu_name === this.menuName);
        this.activeTab = indice;
      });
    },
    handleScroll() {
      this.scrollPosition = window.scrollY;
      this.position = this.scrollPosition;
    },
  },

  created() {
    const menuName = this.$route.params.menuName;

    if (menuName) {
      this.fetchMenu(menuName);
    } else {
      this.fetchMenu();
    }

    this.loading = true;
    this.fetchBranchInfo();

    // if (this.getMenuNameParams.menuName) {
    //   this.fetchMenu(this.getMenuNameParams.index, this.getMenuNameParams.menuName);
    //   this.$store.commit("menu/setMenuNameParams", {});
    // } else {
    //   this.fetchMenu();
    // }
    this.fetchBranchConfig();
    this.fetchBranchDetails();
  },
  mounted() {
    this.fetchBusinessInfo();

    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.addEventListener("scroll", this.handleScroll, { passive: true });
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.card-subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/images/joinbg.webp");
  background-size: cover;
}
.q-dialog__inner--maximized > div {
  left: auto !important; /* Anula la posición izquierda */
  right: 0 !important; /* Alinea el modal a la derecha */
}
.loading {
  width: 100%;
  min-height: 300px;
  margin-top: 250px;
}
.tabs-menu {
  height: 60px;
}

.body-font {
  font-family: var(--q-body-font);
}
.custom-active {
  color: var(--q-bg-color) !important;
}
.button {
  background-color: #04aa6d;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.fixed-menu {
  width: 100%;
  max-width: 100vw;
  height: 55px;
}
.fixed-call {
  display: none;
  height: 55px;
}
.menu-layout {
  min-height: 600px;
  background-color: #082136;
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Hace que la imagen cubra completamente el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */

  max-width: 100vw;
  // height: calc(100vh - 50px);
  display: grid;

  // grid-template-rows: 166px 110px calc(100vh - 326px);
  scroll-behavior: smooth;
  padding-left: 140px;
  padding-right: 140px;
  height: auto;
  // max-height: 100vh;
}

.fab-btn {
  width: calc(100vw - 20px);
  max-width: 400px;
  min-width: 280px;
  border-radius: 10px;
  font-weight: bold;
}
.another-header {
  display: none;
}
.fixed-header {
  display: block;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: var(--q-bg-color);
  width: 100%;
  max-width: 1010px;
  min-width: 280px;
  display: flex;
  align-items: center;
  padding: 46px 20px 0;
}
@media screen and (max-width: 415px) {
  .fixed-header {
    display: block;
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: var(--q-bg-color);
    width: 100%;
    max-width: 425px;
    min-width: 280px;
    display: flex;
    align-items: center;
    padding: 43px 20px 0;
  }
  .menu-layout {
    max-width: 425px;
    height: auto;
    display: grid;

    // grid-template-rows: 166px 110px calc(100vh - 326px);
    scroll-behavior: smooth;
  }

  .buttons button {
    width: 112px;
  }

  .tabs-menu {
    height: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .menu-layout {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media screen and (max-width: 883px) {
  .fixed-menu {
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 55px;
    right: 0;
    border-top: solid #dee1e4 2px;
  }
  .fixed-call {
    left: 0;
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 55px;
    border-top: solid #dee1e4 2px;
  }

  .menu-layout {
    padding-left: 21px;
    padding-right: 21px;
  }
}
</style>
